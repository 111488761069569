export const convertHtmlToStringMixing = {
    data() {
      return {
       
      };
    },
    created: function() {
     
    },
    methods: {
        setHtmlConveter: function(html) {
          // Create a new div element
        var tempDivElement = document.createElement("div");

        // Set the HTML content with the given value
        tempDivElement.innerHTML = html;

        // Retrieve the text property of the element 
        return tempDivElement.textContent || tempDivElement.innerText || "";
      }
    }
  };