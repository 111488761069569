
<script>
import CKEditor from "@ckeditor/ckeditor5-vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import TagInput from "../taginput.vue";
import { composeEmailMethods } from "../../state/helpers/composeemail";
import { convertHtmlToStringMixing } from "../../utils/converthtml";
const toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file.file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
});


export default {
  mixins:[convertHtmlToStringMixing],
  props: ["removeMailBox", "item", "enableCc", "enableBcc", "addSubject", "channel"],
  components: { ckeditor: CKEditor.component, TagInput },
  data() {
    return {
      editor: ClassicEditor,
      editorData: "",
      editorConfig: {
        toolbarLocation: "bottom",
      },
      to_placeholder: "add more To address",
      cc_placeholder: "cc",
      bcc_placeholder: "bcc",
      fileRecords: [],
      uploadUrl: "https://www.mocky.io/v2/5d4fb20b3000005c111099e3",
      uploadHeaders: { "X-Test-Header": "vue-file-agent" },
      fileRecordsForUpload: [], // maintain an upload queue
      attachments:[],
      is_attachment:false,
      is_draft:false,
    };
  },
  methods: {
    ...composeEmailMethods,
    sendEmail(email) {
      if (email.to.length > 0) {
        
        let data = email;
        data.attachments = this.attachments;
        data.is_draft = this.is_draft;
        data.body = this.item.body;
        delete data.id; 
        delete data.is_cc_enable,
        delete data.is_bcc_enable
        this.add(data)
          .then(() => {
             this.removeMailBox(email);
          })
          .catch(() => {
        
          });
      }
    },
   sendDraftEmail(email) {
      if (email.to.length > 0) {
        
        let data = email;
        data.attachments = this.attachments;
        data.is_draft = true;
        data.body = this.item.body;
        delete data.id; 
        delete data.is_cc_enable,
        delete data.is_bcc_enable
        this.add(data)
          .then(() => {
             this.removeMailBox(email);
          })
          .catch(() => {
        
          });
      }
    },
    uploadFiles: function() {
      // Using the default uploader. You may use another uploader instead.
      this.$refs.vueFileAgent.upload(
        this.uploadUrl,
        this.uploadHeaders,
        this.fileRecordsForUpload
      );
      this.fileRecordsForUpload = [];
    },
    deleteUploadedFile: function(fileRecord) {
      // Using the default uploader. You may use another uploader instead.
      this.$refs.vueFileAgent.deleteUpload(
        this.uploadUrl,
        this.uploadHeaders,
        fileRecord
      );
    },
    filesSelected: function(fileRecordsNewlySelected) {
      
      var validFileRecords = fileRecordsNewlySelected.filter(
        (fileRecord) => !fileRecord.error
      );
      this.fileRecordsForUpload = this.fileRecordsForUpload.concat(
        validFileRecords
      );

      let files = this.fileRecordsForUpload;
      const fileData = []; 
      files.forEach(async (file) => {
       const data = await toBase64(file);
      
       fileData.push({name:file.file.name, file:data});
     })

    this.attachments = fileData;
     
    },
    onBeforeDelete: function(fileRecord) {
      var i = this.fileRecordsForUpload.indexOf(fileRecord);
      if (i !== -1) {
        // queued file, not yet uploaded. Just remove from the arrays
        this.fileRecordsForUpload.splice(i, 1);
        var k = this.fileRecords.indexOf(fileRecord);
        if (k !== -1) this.fileRecords.splice(k, 1);
      } else {
        if (confirm("Are you sure you want to delete?")) {
          this.$refs.vueFileAgent.deleteFileRecord(fileRecord); // will trigger 'delete' event
        }
      }
    },
    fileDeleted: function(fileRecord) {
      var i = this.fileRecordsForUpload.indexOf(fileRecord);
      if (i !== -1) {
        this.fileRecordsForUpload.splice(i, 1);
      } else {
        this.deleteUploadedFile(fileRecord);
      }
    },
    showAttachment(){
      this.is_attachment = true;
    },
    onEditorInput(data){
      this.item.body = this.setHtmlConveter(data)
    },
  },
  mounted(){
    console.log("channel data", this.channel)
  }
};
</script>

<template>
  <div class="compose-email-box">
    <div class="compose-email-card-box">
      <div class="compose-input-title">
        <span>{{
          item.subject.length > 0 ? item.subject : "New Message"
        }}</span>
        <i class="fas fa-times" @click="removeMailBox(item)"></i>
      </div>
      <div>
        <TagInput :tags="item.to" :placeholder="to_placeholder" />

        <TagInput
          v-if="item.is_cc_enable"
          :tags="item.cc"
          :placeholder="cc_placeholder"
        />
        <TagInput
          v-if="item.is_bcc_enable"
          :tags="item.bcc"
          :placeholder="bcc_placeholder"
        />
        <div class="cc__link__box">
          <div class="add-cc__bbc_box">
            <span v-if="!item.is_cc_enable" @click="enableCc(item.id)">cc</span>
            <span v-if="!item.is_bcc_enable" @click="enableBcc(item.id)"
              >bcc</span
            >
          </div>
        </div>
      </div>
      <div class="subject_input">
        <input
          class="form-control"
          v-model="item.subject"
          placeholder="Subject"
        />
      </div>
      <div>
        <ckeditor
          v-model="item.html_body"
          :editor="editor"
          :config="editorConfig"
          @input="onEditorInput"
        ></ckeditor>
      </div>
      <div class="file-upload-view" >
        <VueFileAgent
          ref="vueFileAgent"
          :theme="'list'"
          :multiple="true"
          :deletable="true"
          :meta="true"
          
          :maxSize="'10MB'"
          :maxFiles="14"
          :helpText="'Choose document'"
          :errorText="{
            type: 'Invalid file type. Only images or zip Allowed',
            size: 'Files should not exceed 10MB in size',
          }"
          v-if="is_attachment"
          @select="filesSelected($event)"
          @beforedelete="onBeforeDelete($event)"
          @delete="fileDeleted($event)"
          v-model="fileRecords"
        ></VueFileAgent>
       
      </div>
       
      <div class="compose-footer-view">
        <div class="compose-main-footer-item">
        
          <i class="fas fa-paperclip text-primary compose-delete-icon" @click="showAttachment"></i
          >

          <div class="compose-outlite-box">
             <i class="far fa-trash-alt compose-delete-icon" style="color:#c2c2c2; cursor:pointer;" @click="removeMailBox(item)"></i
          >
          <div class="app_default_button primary_outline_view" id="read-later" @click="sendDraftEmail(item)">
           <span>Write Later</span>
        </div>

        <div class="app_default_button primary_outline_view" id="read-later"  @click="sendEmail(item)">
           <span>Send</span> <i class="fa fa-paper-plane ml-2"></i>
        </div>
          </div>
         
        </div>
      </div>
    </div>
  </div>
</template>
