<template>
  <div class="empty_box_view">
      <img :src="emptyBox" alt="" />
      <h4>Data Not Found</h4>
  </div>

</template>

<script>
import emptyBox from "../assets/images/app_icons/empty.png"
export default {
  data(){
    return {
      emptyBox,
    }
  }
}
</script>

<style>

</style>