<script>
import { communicationChannelComputed } from "../../state/helpers/communicationchannel";
import CKEditor from "@ckeditor/ckeditor5-vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Swal from "sweetalert2";
import { inboxMethods } from "../../state/helpers/inbox";
import TagInput from "../taginput.vue";
import { composeEmailMethods } from "../../state/helpers/composeemail";
import timerIcon from "../../assets/images/app_icons/timer-clock.png"
import alarmIcon from "../../assets/images/app_icons/alarm-clock.png"
import readLaterIcon from "../../assets/images/app_icons/readlater.png"
import fowardIcon from "../../assets/images/app_icons/foward.png"
import replyAllIcon from "../../assets/images/app_icons/reply-all.png"
import replyIcon from "../../assets/images/app_icons/reply.png"
import replyLater from "../../assets/images/app_icons/replylater.png"
import moment from 'moment';
/**
 * Email-read component
 */

const toBase64 = file => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file.file);
  reader.onload = () => resolve(reader.result);
  reader.onerror = error => reject(error);
});


export default {
  page: {
    title: "Read Email",
    meta: [{ name: "description" }],
  },
  props: ["email_details", "hideDetails", "showEmail", "contact_details"],
  components: {
    ckeditor: CKEditor.component,
    TagInput,
  },
  data() {
    return {
      timerIcon,
      alarmIcon,
      fowardIcon,
      replyAllIcon,
      readLaterIcon,
      replyLater,
      replyIcon,
      title: "Read Email",
      to_email: [],
      cc_email: [],
      bcc_email: [],
      to_placeholder: "To",
      cc_placeholder: "CC",
      bcc_placeholder: "Bcc",
      is_reply: false,
      is_reply_all: false,
      is_forward: false,
      is_cc_enable: false,
      is_bcc_enable: false,
      body: '',
      items: [
        {
          text: "Email",
          href: "/",
        },
        {
          text: "Read Email",
          active: true,
        },
      ],
      showModal: false,
      editor: ClassicEditor,
      editorData: "",
      fileRecords: [],
      uploadUrl: "https://www.mocky.io/v2/5d4fb20b3000005c111099e3",
      uploadHeaders: { "X-Test-Header": "vue-file-agent" },
      fileRecordsForUpload: [], // maintain an upload queue
      attachments: [],
      is_attachment: false,
      is_menu_show: false,
    };
  },
  computed: {
    ...communicationChannelComputed,
    single_communication_channel: {
      get: function () {
        return this.show_communication_channel;
      },
    },
  },
  methods: {
    ...inboxMethods,
    ...composeEmailMethods,
    date(date) {
      return moment(date).format("ddd DD-MMM-YY hh:mm a");
    },
    showMenu(){
      this.is_menu_show = true;
    }, 
    hideMenu(){
      this.is_menu_show = false;
    },
    deleteData(id, data) {
      Swal.fire({
        title: "Are you sure?",
        text: "You want to delete this email message !",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.value) {
          this.deleteEmails(id)
            .then(() => {
              this.showEmail(data);
              this.hideDetails();
            })
            .catch(() => {

            });
        }
      });
    },
    addToReplyLater() {
      const data = {
        email_id: this.email_details.id,
        channel_id: this.single_communication_channel.id,
      }

      this.createReplyLater(data)
        .then(() => {
          this.hideDetails();
        })
    },
    addToReadLater() {
      const data = {
        email_id: this.email_details.id,
        channel_id: this.single_communication_channel.id,
      }
      this.addReadLater(data)
        .then(() => {
          this.hideDetails();
        })
    },

    showReply() {
      this.is_reply = true;
      const data = [];
      if(this.single_communication_channel.email_account.username !== this.email_details.from[0].Email){
      this.email_details.from.forEach((item) => {
        data.push(item.Email);
      })
      }else{
      this.email_details.from.forEach((item) => {
        data.push(item.Email);
      })
      }

      this.to_email = data;
      this.editorData = "";
      this.attachments = [];
    },
    showReplyAll() {
      this.is_reply_all = true;
      const data = [];
      this.to_email = data;
      this.cc_eamil = this.email_details.cc;
      this.bcc_email = this.email_details.bcc;
      this.attachments = [];
    },
    showFoward() {
      this.is_forward = true;
      this.editorData = this.email_details.html_body ? this.email_details.html_body : this.email_details.body;
    },
    removeMailBox() {
      this.is_forward = false;
      this.is_reply_all = false;
      this.is_reply = false;
      this.to_email = [];
      this.cc_eamil = [];
      this.bcc_email = [];
    },
    enableCc() {
      this.is_cc_enable = true;
    },
    enableBcc() {
      this.is_bcc_enable = true;
    },

    replyEmail() {
      let data = {
        to: this.to_email,
        cc: this.cc_email,
        from: this.single_communication_channel.email_account.username,
        bcc: this.bcc_email,
        body: this.editorData,
        html_body: this.editorData,
        subject: this.email_details.subject,
        email_account_id: this.single_communication_channel.email_account.id,
        message_id: this.email_details.message_id,
        is_draft: false,
        attachments:this.attachments,
      };

      this.replyEmailMessage(data)
        .then(() => {
          this.removeMailBox();
          this.fileRecords = [];
          this.fileRecordsForUpload = []; // maintain an upload queue
          this.attachments = [];
        })
        .catch((error) => {
          this.$bvToast.toast(`${error.error}`, {
            title: `Error !`,
            toaster: "b-toaster-top-right",
            solid: true,
            appendToast: false,
            variant: "danger",
          });
        });
    },
    replyAll() { },
    forward() {
      let data = {
        to: this.to_email,
        cc: this.cc_email,
        from: this.single_communication_channel.email_account.username,
        bcc: this.bcc_email,
        body: this.editorData,
        subject: this.email_details.e_subject,
        email_account_id: this.single_communication_channel.email_account.id,
        message_id: this.email_details.message_id,
        attachments: this.attachments
      };

      this.add(data)
        .then(() => {
          this.removeMailBox();
        })
        .catch((error) => {
          this.$bvToast.toast(`${error.error}`, {
            title: `Error !`,
            toaster: "b-toaster-top-right",
            solid: true,
            appendToast: false,
            variant: "danger",
          });
        });
    },
    sendDraftEmail(email) {
      let data = {
        to: this.to_email,
        cc: this.cc_email,
        from: this.single_communication_channel.email_account.username,
        bcc: this.bcc_email,
        body: this.editorData,
        html_body: this.editorData,
        subject: this.email_details.subject,
        email_account_id: this.single_communication_channel.email_account.id,
        is_draft: true,
        attachments: this.attachments,
      };
        this.add(data)
          .then(() => {
             this.removeMailBox(email);
          })
          .catch(() => {
        
          });
      
    },
    uploadFiles: function () {
      // Using the default uploader. You may use another uploader instead.
      this.$refs.vueFileAgent.upload(
        this.uploadUrl,
        this.uploadHeaders,
        this.fileRecordsForUpload
      );
      this.fileRecordsForUpload = [];
    },
    deleteUploadedFile: function (fileRecord) {
      // Using the default uploader. You may use another uploader instead.
      this.$refs.vueFileAgent.deleteUpload(
        this.uploadUrl,
        this.uploadHeaders,
        fileRecord
      );
    },
    filesSelected: function (fileRecordsNewlySelected) {

      var validFileRecords = fileRecordsNewlySelected.filter(
        (fileRecord) => !fileRecord.error
      );
      this.fileRecordsForUpload = this.fileRecordsForUpload.concat(
        validFileRecords
      );

      let files = this.fileRecordsForUpload;
      const fileData = [];
      files.forEach(async (file) => {
        const data = await toBase64(file);
        const filename = { file: data };
        fileData.push(filename);
      })

      this.attachments = fileData;

    },
    onBeforeDelete: function (fileRecord) {
      var i = this.fileRecordsForUpload.indexOf(fileRecord);
      if (i !== -1) {
        // queued file, not yet uploaded. Just remove from the arrays
        this.fileRecordsForUpload.splice(i, 1);
        var k = this.fileRecords.indexOf(fileRecord);
        if (k !== -1) this.fileRecords.splice(k, 1);
      } else {
        if (confirm("Are you sure you want to delete?")) {
          this.$refs.vueFileAgent.deleteFileRecord(fileRecord); // will trigger 'delete' event
        }
      }
    },
    fileDeleted: function (fileRecord) {
      var i = this.fileRecordsForUpload.indexOf(fileRecord);
      if (i !== -1) {
        this.fileRecordsForUpload.splice(i, 1);
      } else {
        this.deleteUploadedFile(fileRecord);
      }
    },
    showAttachment() {
      this.is_attachment = !this.is_attachment;
    },
    getDefaultName(email) {
      const name = email.split('@')[0];
      return name;
    }
  },
  mounted() { },
};
</script>

<template>
  <!-- Right Sidebar -->
  <div>
    <!-- <div class="channel_email_details_view">
         <i :class="single_communication_channel.icon" :style="{color:single_communication_channel.icon_color + ' !important'}">
             </i>
         <span class="channe_text">{{ single_communication_channel.title + ' - ' + single_communication_channel.email_account.username  }}</span>
      </div> -->

    <!-- <hr/> -->

    <div class="read_email_box_view">
      <!-- <div class="media mb-4">
        <div class="user-icon-box bg-primary">
          <i class="fas fa-user-alt"></i>
        </div>
        <div class="media-body" v-for="(item, index) in email_details.to" :key="index">
          <h5 class="email_details_address_name">{{ item.Name === '' ? getDefaultName(item.Email) : item.Name }}</h5>
          <span class="text-muted email_details_address_email">

            <i class="mdi mdi-email-open-outline incoming"
              v-if="single_communication_channel.email_account.username !== email_details.from[0].Email"></i>
            <i class="far fa-paper-plane sent"
              v-if="single_communication_channel.email_account.username === email_details.from[0].Email"></i>
            {{ item.Email }}

          </span>
          <span class="date_view">{{ single_communication_channel.email_account.username !== email_details.from[0].Email
              ? 'Received: ' : 'Sent: '
          }} {{ date(email_details.date) }} </span>
        </div>
      </div> -->

      <div class="group_contact_details_view_item">
        <div class="group_contact_details_item">
          <div class="group_contact_details" >
            <span class="heading">To: </span>
            <span class="contact" v-for="(item, index) in email_details.to" :key="index">{{ item.Email + ', ' }}</span>
          </div>
          
        <span class="date_view">{{ single_communication_channel.email_account.username !== email_details.from[0].Email
              ? 'Received: ' : 'Sent: '
          }} {{ date(email_details.date) }} </span>

          <div class="group_contact_details">
            <span class="heading">cc: </span>
            <span class="contact" v-for="(item, index) in email_details.cc" :key="index">{{ item.Email + ', ' }}</span>
          </div>

          <div class="group_contact_details mb-5">
            <span class="heading">bcc: </span>
            <span class="contact" v-for="(item, index) in email_details.bcc" :key="index">{{ item.Email + ', ' }}</span>
          </div>
        </div>

      </div>

      <div class="reply_icon mr-5">
        <div class="button-list-view" v-if="is_menu_show">
        <div class="app_default_button primary_outline_view" id="read-later" @click="addToReadLater">
           <span>Read Later</span>
        </div>
        <div class="app_default_button primary_outline_view" id="reply-later" @click="addToReplyLater">
           <span>Reply Later</span>
        </div>


        <div class="app_default_button primary_outline_view" id="forward-email" @click="showFoward">
          <span>Foward</span>
        </div>

        <div class="app_default_button primary_outline_view" id="reply-all" @click="showReply">
         <span>Reply All</span>
        </div>

        <div class="app_default_button primary_outline_view" id="reply" @click="showReply">
          <span>Reply</span>
        </div>
        <div class="app_default_button danger_outline_view" id="reply" @click="deleteData(email_details.id, contact_details)">
          <span>Delete</span>
        </div>
        <div class="button-close-icon" @click="hideMenu">
          <i class="fas fa-times" style="font-size: 24px;"></i>
        </div>

        </div>

        <div class="button-menu-icon-view" @click="showMenu">
          <i class="fas fa-ellipsis-v" style="font-size: 24px;"></i>
        </div>

      </div>

      <!-- <div class="reply_icon">

   <div class="icon_box" id="delete">
            <b-tooltip target="delete">Delete</b-tooltip>
            <i class="fas fa-trash" style="color:#ef4444"></i>
          </div>
        
        </div> -->
    </div>


    <h3 class="font-size-18 mt-2 email_subject_view">{{ email_details.subject }}</h3>

    <div class="e_message_modal_view mt-4">

      <div class="email_body_view">

        <div v-html="
          email_details.html_body
            ? email_details.html_body
            : email_details.text_body
        " />

        
   
      <!-- reply content start -->
      <div class="reply-email-messsag-box mt-4" v-if="is_reply">
        <div class="user-icon-box bg-success">
          <i class="fas fa-user-alt"></i>
        </div>
        <div class="reply-email-item" v-if="is_reply">
          <div class="reply-email-box">
            <TagInput :tags="to_email" :placeholder="to_placeholder" />
            <ckeditor v-model="editorData" :editor="editor"></ckeditor>
            <div class="file-upload-view">
              <VueFileAgent ref="vueFileAgent" :theme="'list'" :multiple="true" :deletable="true" :meta="true"
                :accept="'image/*,.zip, .pdf, .docx, .doc,'" :maxSize="'10MB'" :maxFiles="14"
                :helpText="'Choose images,pdf, docx, doc, zip files'" :errorText="{
                  type: 'Invalid file type. Only images or zip Allowed',
                  size: 'Files should not exceed 10MB in size',
                }" v-if="is_attachment" @select="filesSelected($event)" @beforedelete="onBeforeDelete($event)"
                @delete="fileDeleted($event)" v-model="fileRecords"></VueFileAgent>

            </div>
          </div>
          <div class="reply-footer-view">
            <div class="compose-main-footer-item">

              <i class="fas fa-paperclip text-primary compose-delete-icon" @click="showAttachment"></i>

              <div class="compose-outlite-box">
                <i class="far fa-trash-alt compose-delete-icon" style="color:#c2c2c2; cursor:pointer;"
                  @click="removeMailBox"></i>
                  <div class="app_default_button primary_outline_view" id="read-later" @click="sendDraftEmail">
           <span>Write Later</span>
        </div>

        <div class="app_default_button primary_outline_view" id="read-later"  @click="replyEmail">
           <span>Send</span> <i class="fa fa-paper-plane ml-2"></i>
        </div>

              </div>

            </div>
          </div>
        </div>
      </div>
      <!-- reply content end -->

            <!-- forward content start -->
      <div class="reply-email-messsag-box" v-if="is_forward">
        <div class="user-icon-box bg-success">
          <i class="fas fa-user-alt"></i>
        </div>
        <div class="reply-email-item">
          <div class="reply-email-box">
            <TagInput :tags="to_email" :placeholder="to_placeholder" />
            <TagInput v-if="is_cc_enable" :tags="cc_email" :placeholder="cc_placeholder" />
            <TagInput v-if="is_bcc_enable" :tags="bcc_email" :placeholder="bcc_placeholder" />
            <div class="cc__link__box">
              <div class="add-cc__bbc_box">
                <span v-if="!is_cc_enable" @click="enableCc(email_details.id)">CC</span>
                <span v-if="!is_bcc_enable" @click="enableBcc(email_details.id)">BCC</span>
              </div>
            </div>
            <ckeditor v-model="editorData" :editor="editor"></ckeditor>
            <div class="file-upload-view">
              <VueFileAgent ref="vueFileAgent" :theme="'list'" :multiple="true" :deletable="true" :meta="true"
                :accept="'image/*,.zip, .pdf, .docx, .doc,'" :maxSize="'10MB'" :maxFiles="14"
                :helpText="'Choose images,pdf, docx, doc, zip files'" :errorText="{
                  type: 'Invalid file type. Only images or zip Allowed',
                  size: 'Files should not exceed 10MB in size',
                }" v-if="is_attachment" @select="filesSelected($event)" @beforedelete="onBeforeDelete($event)"
                @delete="fileDeleted($event)" v-model="fileRecords"></VueFileAgent>

            </div>
          </div>
          <div class="reply-footer-view">
            <div class="compose-main-footer-item">

              <i class="fas fa-paperclip text-primary compose-delete-icon" @click="showAttachment"></i>

              <div class="compose-outlite-box">
                <i class="far fa-trash-alt compose-delete-icon" style="color:#c2c2c2; cursor:pointer;"
                  @click="removeMailBox"></i>
                  <div class="app_default_button primary_outline_view" id="read-later" @click="sendDraftEmail">
           <span>Write Later</span>
        </div>

        <div class="app_default_button primary_outline_view" id="read-later"  @click="forward">
           <span>Send</span> <i class="fa fa-paper-plane ml-2"></i>
        </div>
              </div>

            </div>
          </div>
        </div>
      </div>
      <!-- forward content end -->
      </div>


      <div class="row attachment_view" v-if="email_details.attachments && email_details.attachments.length > 0">
        <div class="col-xl-12 col-12 col-md-12">
          <div class="attactments-card">
            <div class="attactments-files">
              <div class="attachemnts-view" v-for="(item, index) in email_details.attachments" :key="index">
                <img v-if="
                  item.a_type === 'JPG' ||
                  item.a_type === 'PNG' ||
                  item.a_type === 'JPEG' ||
                  item.a_type === 'WEBP' ||
                  item.a_type === 'SVG'
                " class="card-img-top img-fluid" :src="item.a_path" alt="Card image cap" />

                <img v-if="
                  item.a_type !== 'JPG' &&
                  item.a_type !== 'PNG' &&
                  item.a_type !== 'JPEG' &&
                  item.a_type !== 'WEBP' &&
                  item.a_type !== 'SVG'
                " class="card-img-top img-fluid" src="@/assets/images/attach.png" alt="Card image cap" />
                <span>{{ item.name | setTruncate(13,
                    "...")
                }}</span>
              </div>
            </div>

            <div class="attactments-footer">
              <div>{{ email_details.attachments && email_details.attachments.length }}
                attachment{{ email_details.attachments.length === 1 ? '' : 's' }}</div>
              <span><i class="mdi mdi-cloud-download mr-2 text-primary"></i>Download All</span>
            </div>
          </div>
        </div>
      </div>

      <!-- <div class="modal-footer-item-view">
        
                <div class="icon_box" id="delete" @click="deleteData(email_details.id, contact_details)">
            <i class="fas fa-trash" style="color:#ef4444"></i>
          </div>
      </div> -->

      <!-- replyall content start -->
      <!-- <div class="reply-email-messsag-box" v-if="is_reply_all">
          <div class="user-icon-box bg-success">
            <i class="fas fa-user-alt"></i>
          </div>
          <div class="reply-email-item">
            <div class="reply-email-box">
              <TagInput :tags="to_email" :placeholder="to_placeholder" />
              <TagInput
                v-if="cc_email.length > 0"
                :tags="cc_email"
                :placeholder="cc_placeholder"
              />
              <ckeditor v-model="editorData" :editor="editor"></ckeditor>
            </div>
            <div class="reply-footer-view">
              <b-button variant="success" @click="sendEmail(item)"
                >Send Message</b-button
              >
              <b-button variant="danger" @click="removeMailBox"
                ><i class="fas fa-trash"></i
              ></b-button>
            </div>
          </div>
        </div> -->
      <!-- replyall content end -->


    </div>
    <!-- <div
          class="btn btn-secondary waves-effect mt-4 mr-2"
          v-if="
            is_reply === false && is_reply_all === false && is_forward === false
          "
          @click="showReplyAll"
        >
          <i class="mdi mdi-reply-all"></i> Reply All
        </div> -->
  
  </div>
  <!-- card -->
</template>
