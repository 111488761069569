<template>
  <div class="empty_box_view">
      <img :src="emptyBox" alt="" />
      <h4>{{ title}}</h4>
  </div>

</template>

<script>
import emptyBox from "../assets/images/app_icons/box.png"
export default {
  props:["title"],
  data(){
    return {
      emptyBox,
    }
  }
}
</script>

<style>

</style>