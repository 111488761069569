<template>
  <div class="ctx-email-app-body">
    <modal name="email-message-modal" :width="1100" height="auto" draggable=".modal-header"
      v-if="single_communication_channel !== undefined">
      <div class="modal-container">
        <div class="modal-header">
          <span>{{ single_communication_channel.title }} - {{ single_communication_channel.email_account === undefined ?
            '' : single_communication_channel.email_account.username
            }} </span>
          <i class="mdi mdi-close-thick" @click="$modal.hide('email-message-modal')"></i>
        </div>
        <div class="modal-body-view">
          <ReadMail v-if="is_details && is_group_view_enable === false" :hideDetails="hideDetails"
            :showEmail="showEmailMessages" :email_details="email_details" :contact_details="emailDetails" />

          <GroupLaterMail v-if="is_details && is_group_view_enable === true" :hideDetails="hideDetails"
            :showEmail="showEmailMessages" :email_details="email_details" :contact_details="emailDetails" />
        </div>
      </div>


    </modal>

    <modal name="add-contact-modal" :width="1000" height="auto" draggable=".modal-header">

      <div class="modal-item">
        <div class="modal-header">
          <span>Add Contact</span>
          <i class="mdi mdi-close-thick" @click="$modal.hide('add-contact-modal')"></i>
        </div>

        <div class="modal-contact-body-view">

          <div role="tablist" class="app-accordion-view">
            <b-card no-body class="mb-1 shadow-none">
              <b-card-header header-tag="header" role="tab">
                <h6 class="m-0">
                  <a v-b-toggle.information class="text-dark" href="javascript: void(0);">Contact Information</a>
                </h6>
              </b-card-header>
              <b-collapse id="information" visible accordion="my-accordion" role="tabpanel">
                <b-card-body>
                  <b-card-text>
                    <div class="row">
                      <div class="col-md-6 mb-4">
                        <div class="contact-form-view">
                          <label class="col-form-label" for="first_name">First Name:</label>
                          <input id="first_name" type="text" class="form-control" name="first_name"
                            v-model="first_name" />
                        </div>
                      </div>
                      <div class="col-md-6 mb-4">
                        <div class="contact-form-view">
                          <label class="col-form-label" for="middle_name">Middle Name:</label>
                          <input id="middle_name" type="text" class="form-control" name="middle_name"
                            v-model="middle_name" />
                        </div>
                      </div>
                      <div class="col-md-6 mb-4">
                        <div class="contact-form-view">
                          <label class="col-form-label" for="last_name">Last Name:</label>
                          <input id="last_name" type="text" class="form-control" name="last_name" v-model="last_name" />
                        </div>
                      </div>

                      <div class="col-md-6 mb-4">
                        <div class="contact-form-view">
                          <label class="col-form-label" for="display_name">Display Name:</label>
                          <input id="display_name" type="text" class="form-control" name="display_name"
                            v-model="display_name" />
                        </div>
                      </div>

                      <div class="col-md-6 mb-4">
                        <div class="contact-form-view">
                          <label class="col-form-label" for="email">Email</label>
                          <input id="email" type="text" class="form-control" name="email" v-model="email" />
                        </div>
                      </div>

                      <div class="col-md-6 mb-4">
                        <div class="contact-form-view">
                          <label class="col-form-label" for="email_two">Email 2</label>
                          <input id="email_two" type="text" class="form-control" name="email_two" v-model="email_two" />
                        </div>
                      </div>

                      <div class="col-md-6 mb-4">
                        <div class="contact-form-view">
                          <label class="col-form-label" for="contact">Contact:</label>
                          <input id="contact" type="text" class="form-control" name="contact" v-model="contact" />
                        </div>
                      </div>

                      <div class="col-md-6 mb-4">
                        <div class="contact-form-view">
                          <label class="col-form-label" for="paticipant_name">Contact Type:</label>
                          <input id="contact_type" type="text" class="form-control" name="contact_type"
                            v-model="contact_type" />
                        </div>
                      </div>
                      <div class="col-md-6 mb-4">
                        <div class="contact-form-view">
                          <label class="col-form-label" for="phone_number">Phone Number</label>
                          <input id="phone_number" type="text" class="form-control" name="phone_number"
                            v-model="phone_number" />
                        </div>
                      </div>

                      <div class="col-md-6 mb-4">
                        <div class="contact-form-view">
                          <label class="col-form-label" for="phone_number_two">Phone Number 2</label>
                          <input id="phone_number_two" type="text" class="form-control" name="phone_number_two"
                            v-model="phone_number_two" />
                        </div>
                      </div>


                      <div class="col-md-6 mb-4">
                        <div class="contact-form-view">
                          <label class="col-form-label" for="photo">Photo</label>
                          <div class="flex-file-upload-vue">
                            <VueFileAgent ref="vueFileAgent" :theme="'list'" :multiple="false" :deletable="true"
                              :meta="true" :accept="'image/*'" :maxSize="'10MB'" :maxFiles="14"
                              :helpText="'Choose images'" :errorText="{
                          type: 'Invalid file type. Only images',
                          size: 'Files should not exceed 10MB in size',
                        }" @select="filesSelected($event)" @beforedelete="onBeforeDelete($event)"
                              @delete="fileDeleted($event)" v-model="fileRecords"></VueFileAgent>
                          </div>

                        </div>
                      </div>

                    </div>

                  </b-card-text>
                </b-card-body>
              </b-collapse>
            </b-card>

            <b-card no-body class="mb-1">
              <b-card-header header-tag="header" role="tab">
                <h6 class="m-0">
                  <a v-b-toggle.connection class="text-dark" href="javascript: void(0);">Connection</a>
                </h6>
              </b-card-header>
              <b-collapse id="connection" accordion="my-accordion" role="tabpanel">
                <b-card-body>
                  <b-card-text>
                    <div class="row">
                      <div class="col-md-6 mb-4">
                        <div class="contact-form-view">
                          <label class="col-form-label" for="cxm">Cxm:</label>
                          <input id="cxm" type="text" class="form-control" name="cxm" v-model="cxm" />
                        </div>
                      </div>

                      <div class="col-md-6 mb-4">
                        <div class="contact-form-view">
                          <label class="col-form-label" for="cxv">Cxv</label>
                          <input id="cxv" type="text" class="form-control" name="cxv" v-model="cxv" />
                        </div>
                      </div>

                      <div class="col-md-6 mb-4">
                        <div class="contact-form-view">
                          <label class="col-form-label" for="facebook">Facebook</label>
                          <input id="facebook" type="text" class="form-control" name="facebook" v-model="facebook" />
                        </div>
                      </div>


                      <div class="col-md-6 mb-4">
                        <div class="contact-form-view">
                          <label class="col-form-label" for="linked_in">Linked In</label>
                          <input id="linked_in" type="text" class="form-control" name="linked_in" v-model="linked_in" />
                        </div>
                      </div>


                      <div class="col-md-6 mb-4">
                        <div class="contact-form-view">
                          <label class="col-form-label" for="instagram">Instagram</label>
                          <input id="instagram" type="text" class="form-control" name="instagram" v-model="instagram" />
                        </div>
                      </div>


                      <div class="col-md-6 mb-4">
                        <div class="contact-form-view">
                          <label class="col-form-label" for="whats_app">Whats App</label>
                          <input id="whats_app" type="text" class="form-control" name="whats_app" v-model="whats_app" />
                        </div>
                      </div>


                      <div class="col-md-6 mb-4">
                        <div class="contact-form-view">
                          <label class="col-form-label" for="skype">Skype</label>
                          <input id="skype" type="text" class="form-control" name="skype" v-model="skype" />
                        </div>
                      </div>



                      <div class="col-md-6 mb-4">
                        <div class="contact-form-view">
                          <label class="col-form-label" for="telegram">Telegram</label>
                          <input id="telegram" type="text" class="form-control" name="telegram" v-model="telegram" />
                        </div>
                      </div>
                    </div>
                  </b-card-text>
                </b-card-body>
              </b-collapse>
            </b-card>

            <b-card no-body class="mb-1">
              <b-card-header header-tag="header" role="tab">
                <h6 class="m-0">
                  <a v-b-toggle.accordion-3 class="text-dark" href="javascript: void(0);">Contact Address</a>
                </h6>
              </b-card-header>
              <b-collapse id="accordion-3" accordion="my-accordion" role="tabpanel">
                <b-card-body>
                  <b-card-text>
                    <h6 class="address_heading_text">Home Address</h6>
                    <hr />

                    <!-- address start -->
                    <div class="row">
                      <div class="col-md-4 mb-2">
                        <div class="">
                          <label class="col-form-label" for="home_address">Address:</label>
                          <input id="home_address" type="text" class="form-control" name="home_address"
                            v-model="home_address" />
                        </div>
                      </div>

                      <div class="col-md-4 mb-2">
                        <div class="">
                          <label class="col-form-label" for="home_address_two">Address 2: </label>
                          <input id="home_address_two" type="text" class="form-control" name="home_address_two"
                            v-model="home_address_two" />
                        </div>
                      </div>

                      <div class="col-md-4 mb-2">
                        <div class="">
                          <label class="col-form-label" for="country">Country</label>
                          <input id="country" type="text" class="form-control" name="country" v-model="country" />
                        </div>
                      </div>

                      <div class="col-md-4 mb-2">
                        <div class="">
                          <label class="col-form-label" for="city">City:</label>
                          <input id="city" type="text" class="form-control" name="city" v-model="city" />
                        </div>
                      </div>

                      <div class="col-md-4 mb-2">
                        <div class="">
                          <label class="col-form-label" for="state">State:</label>
                          <input id="state" type="text" class="form-control" name="state" v-model="state" />
                        </div>
                      </div>

                      <div class="col-md-4 mb-2">
                        <div class="">
                          <label class="col-form-label" for="postal_code">Postal Code:</label>
                          <input id="postal_code" type="text" class="form-control" name="postal_code"
                            v-model="postal_code" />
                        </div>
                      </div>
                    </div>
                    <!-- address end -->
                    <h6 class="address_heading_text">Business Address</h6>
                    <hr />
                    <!-- address start -->
                    <div class="row">
                      <div class="col-md-4 mb-2">
                        <div class="">
                          <label class="col-form-label" for="business_address">Address:</label>
                          <input id="business_address" type="text" class="form-control" name="business_address"
                            v-model="business_address" />
                        </div>
                      </div>

                      <div class="col-md-4 mb-2">
                        <div class="">
                          <label class="col-form-label" for="business_address_two">Address 2: </label>
                          <input id="business_address_two" type="text" class="form-control" name="business_address_two"
                            v-model="business_address_two" />
                        </div>
                      </div>

                      <div class="col-md-4 mb-2">
                        <div class="">
                          <label class="col-form-label" for="business_country">Country</label>
                          <input id="business_country" type="text" class="form-control" name="business_country"
                            v-model="business_country" />
                        </div>
                      </div>

                      <div class="col-md-4 mb-2">
                        <div class="">
                          <label class="col-form-label" for="business_city">City:</label>
                          <input id="business_city" type="text" class="form-control" name="business_city"
                            v-model="business_city" />
                        </div>
                      </div>

                      <div class="col-md-4 mb-2">
                        <div class="">
                          <label class="col-form-label" for="business_state">State:</label>
                          <input id="business_state" type="text" class="form-control" name="business_state"
                            v-model="business_state" />
                        </div>
                      </div>

                      <div class="col-md-4 mb-2">
                        <div class="">
                          <label class="col-form-label" for="business_postal_code">Postal Code:</label>
                          <input id="business_postal_code" type="text" class="form-control" name="business_postal_code"
                            v-model="business_postal_code" />
                        </div>
                      </div>
                    </div>
                    <!-- address end -->
                    <h6 class="address_heading_text">Mailing Address</h6>
                    <hr />
                    <!-- address start -->
                    <div class="row">
                      <div class="col-md-4 mb-2">
                        <div class="">
                          <label class="col-form-label" for="mailing_address">Address:</label>
                          <input id="mailing_address" type="text" class="form-control" name="mailing_address"
                            v-model="mailing_address" />
                        </div>
                      </div>

                      <div class="col-md-4 mb-2">
                        <div class="">
                          <label class="col-form-label" for="mailing_address_two">Address 2: </label>
                          <input id="mailing_address_two" type="text" class="form-control" name="mailing_address_two"
                            v-model="mailing_address_two" />
                        </div>
                      </div>

                      <div class="col-md-4 mb-2">
                        <div class="">
                          <label class="col-form-label" for="mailing_country">Country</label>
                          <input id="mailing_country" type="text" class="form-control" name="mailing_country"
                            v-model="mailing_country" />
                        </div>
                      </div>

                      <div class="col-md-4 mb-2">
                        <div class="">
                          <label class="col-form-label" for="mailing_city">City:</label>
                          <input id="mailing_city" type="text" class="form-control" name="mailing_city"
                            v-model="mailing_city" />
                        </div>
                      </div>

                      <div class="col-md-4 mb-2">
                        <div class="">
                          <label class="col-form-label" for="mailing_state">State:</label>
                          <input id="mailing_state" type="text" class="form-control" name="mailing_state"
                            v-model="mailing_state" />
                        </div>
                      </div>

                      <div class="col-md-4 mb-2">
                        <div class="">
                          <label class="col-form-label" for="mailing_postal_code">Postal Code:</label>
                          <input id="mailing_postal_code" type="text" class="form-control" name="mailing_postal_code"
                            v-model="mailing_postal_code" />
                        </div>
                      </div>
                    </div>
                    <!-- address end -->
                  </b-card-text>
                </b-card-body>
              </b-collapse>
            </b-card>
          </div>

          <div class="contact-footer-view">
            <div class="contact-footer-view-item">
              <div class="app_default_button cancel_outline_view" @click="$modal.hide('add-contact-modal')">Cancel
              </div>

            </div>
            <!-- <b-button variant="outline-success" @click="saveUpdatedContact">Save</b-button> -->
            <div class="app_default_button success_outline_view" @click="saveNewContact">
              <span>Save</span>
            </div>
          </div>
        </div>
      </div>

    </modal>

    <modal name="edit-contact-modal" :width="1000" height="auto" draggable=".modal-header">

      <div class="modal-item">
        <div class="modal-header">
          <span>Edit Contact</span>
          <i class="mdi mdi-close-thick" @click="$modal.hide('edit-contact-modal')"></i>
        </div>

        <div class="modal-contact-body-view">

          <div role="tablist" class="app-accordion-view">
            <b-card no-body class="mb-1 shadow-none">
              <b-card-header header-tag="header" role="tab">
                <h6 class="m-0">
                  <a v-b-toggle.information class="text-dark" href="javascript: void(0);">Contact Information</a>
                </h6>
              </b-card-header>
              <b-collapse id="information" visible accordion="my-accordion" role="tabpanel">
                <b-card-body>
                  <b-card-text>
                    <div class="row">
                      <div class="col-md-6 mb-4">
                        <div class="contact-form-view">
                          <label class="col-form-label" for="first_name">First Name:</label>
                          <input id="first_name" type="text" class="form-control" name="first_name"
                            v-model="first_name" />
                        </div>
                      </div>
                      <div class="col-md-6 mb-4">
                        <div class="contact-form-view">
                          <label class="col-form-label" for="middle_name">Middle Name:</label>
                          <input id="middle_name" type="text" class="form-control" name="middle_name"
                            v-model="middle_name" />
                        </div>
                      </div>
                      <div class="col-md-6 mb-4">
                        <div class="contact-form-view">
                          <label class="col-form-label" for="last_name">Last Name:</label>
                          <input id="last_name" type="text" class="form-control" name="last_name" v-model="last_name" />
                        </div>
                      </div>

                      <div class="col-md-6 mb-4">
                        <div class="contact-form-view">
                          <label class="col-form-label" for="display_name">Display Name:</label>
                          <input id="display_name" type="text" class="form-control" name="display_name"
                            v-model="display_name" />
                        </div>
                      </div>

                      <div class="col-md-6 mb-4">
                        <div class="contact-form-view">
                          <label class="col-form-label" for="email">Email</label>
                          <input id="email" type="text" class="form-control" name="email" v-model="email" />
                        </div>
                      </div>

                      <div class="col-md-6 mb-4">
                        <div class="contact-form-view">
                          <label class="col-form-label" for="email_two">Email 2</label>
                          <input id="email_two" type="text" class="form-control" name="email_two" v-model="email_two" />
                        </div>
                      </div>

                      <div class="col-md-6 mb-4">
                        <div class="contact-form-view">
                          <label class="col-form-label" for="contact">Contact:</label>
                          <input id="contact" type="text" class="form-control" name="contact" v-model="contact" />
                        </div>
                      </div>

                      <div class="col-md-6 mb-4">
                        <div class="contact-form-view">
                          <label class="col-form-label" for="paticipant_name">Contact Type:</label>
                          <input id="contact_type" type="text" class="form-control" name="contact_type"
                            v-model="contact_type" />
                        </div>
                      </div>
                      <div class="col-md-6 mb-4">
                        <div class="contact-form-view">
                          <label class="col-form-label" for="phone_number">Phone Number</label>
                          <input id="phone_number" type="text" class="form-control" name="phone_number"
                            v-model="phone_number" />
                        </div>
                      </div>

                      <div class="col-md-6 mb-4">
                        <div class="contact-form-view">
                          <label class="col-form-label" for="phone_number_two">Phone Number 2</label>
                          <input id="phone_number_two" type="text" class="form-control" name="phone_number_two"
                            v-model="phone_number_two" />
                        </div>
                      </div>


                      <div class="col-md-6 mb-4">
                        <div class="contact-form-view">
                          <label class="col-form-label" for="photo">Photo</label>
                          <div class="flex-file-upload-vue">
                            <VueFileAgent ref="vueFileAgent" :theme="'list'" :multiple="false" :deletable="true"
                              :meta="true" :accept="'image/*'" :maxSize="'10MB'" :maxFiles="14"
                              :helpText="'Choose images'" :errorText="{
                                type: 'Invalid file type. Only images',
                                size: 'Files should not exceed 10MB in size',
                              }" @select="filesSelected($event)" @beforedelete="onBeforeDelete($event)"
                              @delete="fileDeleted($event)" v-model="fileRecords"></VueFileAgent>
                          </div>

                        </div>
                      </div>

                    </div>

                  </b-card-text>
                </b-card-body>
              </b-collapse>
            </b-card>

            <b-card no-body class="mb-1">
              <b-card-header header-tag="header" role="tab">
                <h6 class="m-0">
                  <a v-b-toggle.connection class="text-dark" href="javascript: void(0);">Connection</a>
                </h6>
              </b-card-header>
              <b-collapse id="connection" accordion="my-accordion" role="tabpanel">
                <b-card-body>
                  <b-card-text>
                    <div class="row">
                      <div class="col-md-6 mb-4">
                        <div class="contact-form-view">
                          <label class="col-form-label" for="cxm">Cxm:</label>
                          <input id="cxm" type="text" class="form-control" name="cxm" v-model="cxm" />
                        </div>
                      </div>

                      <div class="col-md-6 mb-4">
                        <div class="contact-form-view">
                          <label class="col-form-label" for="cxv">Cxv</label>
                          <input id="cxv" type="text" class="form-control" name="cxv" v-model="cxv" />
                        </div>
                      </div>

                      <div class="col-md-6 mb-4">
                        <div class="contact-form-view">
                          <label class="col-form-label" for="facebook">Facebook</label>
                          <input id="facebook" type="text" class="form-control" name="facebook" v-model="facebook" />
                        </div>
                      </div>


                      <div class="col-md-6 mb-4">
                        <div class="contact-form-view">
                          <label class="col-form-label" for="linked_in">Linked In</label>
                          <input id="linked_in" type="text" class="form-control" name="linked_in" v-model="linked_in" />
                        </div>
                      </div>


                      <div class="col-md-6 mb-4">
                        <div class="contact-form-view">
                          <label class="col-form-label" for="instagram">Instagram</label>
                          <input id="instagram" type="text" class="form-control" name="instagram" v-model="instagram" />
                        </div>
                      </div>


                      <div class="col-md-6 mb-4">
                        <div class="contact-form-view">
                          <label class="col-form-label" for="whats_app">Whats App</label>
                          <input id="whats_app" type="text" class="form-control" name="whats_app" v-model="whats_app" />
                        </div>
                      </div>


                      <div class="col-md-6 mb-4">
                        <div class="contact-form-view">
                          <label class="col-form-label" for="skype">Skype</label>
                          <input id="skype" type="text" class="form-control" name="skype" v-model="skype" />
                        </div>
                      </div>



                      <div class="col-md-6 mb-4">
                        <div class="contact-form-view">
                          <label class="col-form-label" for="telegram">Telegram</label>
                          <input id="telegram" type="text" class="form-control" name="telegram" v-model="telegram" />
                        </div>
                      </div>
                    </div>
                  </b-card-text>
                </b-card-body>
              </b-collapse>
            </b-card>

            <b-card no-body class="mb-1">
              <b-card-header header-tag="header" role="tab">
                <h6 class="m-0">
                  <a v-b-toggle.accordion-3 class="text-dark" href="javascript: void(0);">Contact Address</a>
                </h6>
              </b-card-header>
              <b-collapse id="accordion-3" accordion="my-accordion" role="tabpanel">
                <b-card-body>
                  <b-card-text>
                    <h6 class="address_heading_text">Home Address</h6>
                    <hr />

                    <!-- address start -->
                    <div class="row">
                      <div class="col-md-4 mb-2">
                        <div class="">
                          <label class="col-form-label" for="home_address">Address:</label>
                          <input id="home_address" type="text" class="form-control" name="home_address"
                            v-model="home_address" />
                        </div>
                      </div>

                      <div class="col-md-4 mb-2">
                        <div class="">
                          <label class="col-form-label" for="home_address_two">Address 2: </label>
                          <input id="home_address_two" type="text" class="form-control" name="home_address_two"
                            v-model="home_address_two" />
                        </div>
                      </div>

                      <div class="col-md-4 mb-2">
                        <div class="">
                          <label class="col-form-label" for="country">Country</label>
                          <input id="country" type="text" class="form-control" name="country" v-model="country" />
                        </div>
                      </div>

                      <div class="col-md-4 mb-2">
                        <div class="">
                          <label class="col-form-label" for="city">City:</label>
                          <input id="city" type="text" class="form-control" name="city" v-model="city" />
                        </div>
                      </div>

                      <div class="col-md-4 mb-2">
                        <div class="">
                          <label class="col-form-label" for="state">State:</label>
                          <input id="state" type="text" class="form-control" name="state" v-model="state" />
                        </div>
                      </div>

                      <div class="col-md-4 mb-2">
                        <div class="">
                          <label class="col-form-label" for="postal_code">Postal Code:</label>
                          <input id="postal_code" type="text" class="form-control" name="postal_code"
                            v-model="postal_code" />
                        </div>
                      </div>
                    </div>
                    <!-- address end -->
                    <h6 class="address_heading_text">Business Address</h6>
                    <hr />
                    <!-- address start -->
                    <div class="row">
                      <div class="col-md-4 mb-2">
                        <div class="">
                          <label class="col-form-label" for="business_address">Address:</label>
                          <input id="business_address" type="text" class="form-control" name="business_address"
                            v-model="business_address" />
                        </div>
                      </div>

                      <div class="col-md-4 mb-2">
                        <div class="">
                          <label class="col-form-label" for="business_address_two">Address 2: </label>
                          <input id="business_address_two" type="text" class="form-control" name="business_address_two"
                            v-model="business_address_two" />
                        </div>
                      </div>

                      <div class="col-md-4 mb-2">
                        <div class="">
                          <label class="col-form-label" for="business_country">Country</label>
                          <input id="business_country" type="text" class="form-control" name="business_country"
                            v-model="business_country" />
                        </div>
                      </div>

                      <div class="col-md-4 mb-2">
                        <div class="">
                          <label class="col-form-label" for="business_city">City:</label>
                          <input id="business_city" type="text" class="form-control" name="business_city"
                            v-model="business_city" />
                        </div>
                      </div>

                      <div class="col-md-4 mb-2">
                        <div class="">
                          <label class="col-form-label" for="business_state">State:</label>
                          <input id="business_state" type="text" class="form-control" name="business_state"
                            v-model="business_state" />
                        </div>
                      </div>

                      <div class="col-md-4 mb-2">
                        <div class="">
                          <label class="col-form-label" for="business_postal_code">Postal Code:</label>
                          <input id="business_postal_code" type="text" class="form-control" name="business_postal_code"
                            v-model="business_postal_code" />
                        </div>
                      </div>
                    </div>
                    <!-- address end -->
                    <h6 class="address_heading_text">Mailing Address</h6>
                    <hr />
                    <!-- address start -->
                    <div class="row">
                      <div class="col-md-4 mb-2">
                        <div class="">
                          <label class="col-form-label" for="mailing_address">Address:</label>
                          <input id="mailing_address" type="text" class="form-control" name="mailing_address"
                            v-model="mailing_address" />
                        </div>
                      </div>

                      <div class="col-md-4 mb-2">
                        <div class="">
                          <label class="col-form-label" for="mailing_address_two">Address 2: </label>
                          <input id="mailing_address_two" type="text" class="form-control" name="mailing_address_two"
                            v-model="mailing_address_two" />
                        </div>
                      </div>

                      <div class="col-md-4 mb-2">
                        <div class="">
                          <label class="col-form-label" for="mailing_country">Country</label>
                          <input id="mailing_country" type="text" class="form-control" name="mailing_country"
                            v-model="mailing_country" />
                        </div>
                      </div>

                      <div class="col-md-4 mb-2">
                        <div class="">
                          <label class="col-form-label" for="mailing_city">City:</label>
                          <input id="mailing_city" type="text" class="form-control" name="mailing_city"
                            v-model="mailing_city" />
                        </div>
                      </div>

                      <div class="col-md-4 mb-2">
                        <div class="">
                          <label class="col-form-label" for="mailing_state">State:</label>
                          <input id="mailing_state" type="text" class="form-control" name="mailing_state"
                            v-model="mailing_state" />
                        </div>
                      </div>

                      <div class="col-md-4 mb-2">
                        <div class="">
                          <label class="col-form-label" for="mailing_postal_code">Postal Code:</label>
                          <input id="mailing_postal_code" type="text" class="form-control" name="mailing_postal_code"
                            v-model="mailing_postal_code" />
                        </div>
                      </div>
                    </div>
                    <!-- address end -->
                  </b-card-text>
                </b-card-body>
              </b-collapse>
            </b-card>
          </div>

          <div class="contact-footer-view">
            <div class="contact-footer-view-item">
              <div class="app_default_button cancel_outline_view" @click="$modal.hide('edit-contact-modal')">Cancel
              </div>

            </div>
            <!-- <b-button variant="outline-success" @click="saveUpdatedContact">Save</b-button> -->
            <div class="app_default_button success_outline_view" @click="saveUpdatedContact">
              <span>Save</span>
            </div>
          </div>
        </div>
      </div>

    </modal>

    <!-- tag modal -->
    <modal name="tags-modal" :width="300" height="auto" draggable=".modal-header">
      <div class="modal-header">
        <span>Add Tags</span>
        <i class="mdi mdi-close-thick" @click="$modal.hide('tags-modal')"></i>
      </div>

      <div class="modal-body-view">
        <div>
          <label class="col-form-label" for="tags">Tags</label>
          <b-form-tags input-id="tags-basic" v-model="tag_names"></b-form-tags>
          <!-- <v-select label="value" taggable multiple v-model="tag_names"></v-select> -->
        </div>


        <div class="contact-footer-view">
          <div class="contact-footer-view-item">
            <div class="app_default_button cancel_outline_view" @click="$modal.hide('tags-modal')">Cancel</div>

          </div>
          <!-- <b-button variant="outline-success" @click="saveUpdatedContact">Save</b-button> -->
          <div class="app_default_button success_outline_view" @click="addNewTags">
            <span>Save</span>
          </div>
        </div>
      </div>

    </modal>
    <!-- folder -->

    <!-- tag to contact modal -->
    <modal name="tags-to-contact-modal" :width="330" height="auto" draggable=".modal-header">
      <div class="modal-header">
        <span>Add Tags to contact</span>
        <i class="mdi mdi-close-thick" @click="$modal.hide('tags-to-contact-modal')"></i>
      </div>


      <div class="modal-body-view">
        <div>
          <label class="col-form-label" for="tags">Tags</label>
          <v-select label="value" :options="tags" multiple v-model="contact_tags"></v-select>
        </div>

        <div class="contact-footer-view">
          <div class="contact-footer-view-item">
            <div class="app_default_button cancel_outline_view" @click="$modal.hide('tags-to-contact-modal')">Cancel
            </div>

          </div>
          <!-- <b-button variant="outline-success" @click="saveUpdatedContact">Save</b-button> -->
          <div class="app_default_button success_outline_view" @click="addTagsToContacts(emailDetails)">
            <span>Save</span>
          </div>
        </div>
      </div>

    </modal>
    <!-- folder -->

    <!-- tag modal -->
    <modal name="folder-modal" :width="300" height="auto" draggable=".modal-header">
      <div class="modal-header">
        <span>Create Folder</span>
        <i class="mdi mdi-close-thick" @click="$modal.hide('folder-modal')"></i>
      </div>

      <div class="modal-body-view">
        <div>
          <label class="col-form-label" for="folder_name">Folder Name</label>
          <input id="folder_name" type="text" class="form-control" name="folder_name" v-model="folder_name" />
        </div>

        <div class="contact-footer-view">
          <div class="contact-footer-view-item">
            <div class="app_default_button cancel_outline_view" @click="$modal.hide('folder-modal')">Cancel</div>

          </div>
          <!-- <b-button variant="outline-success" @click="saveUpdatedContact">Save</b-button> -->
          <div class="app_default_button success_outline_view" @click="createFolder">
            <span>Save</span>
          </div>
        </div>


      </div>

    </modal>
    <!--  -->

    <!-- tag modal -->
    <modal name="edit-group-contact-modal" :width="300" height="auto" draggable=".modal-header">
      <div class="modal-header">
        <span>Edit Group Contact</span>
        <i class="mdi mdi-close-thick" @click="$modal.hide('edit-group-contact-modal')"></i>
      </div>

      <div class="modal-body-view">
        <div>
          <label class="col-form-label" for="group_contact">Group Name</label>
          <input id="group_contact" type="text" class="form-control" name="group_contact" v-model="group_contact" />
        </div>

        <div class="contact-footer-view">
          <div class="contact-footer-view-item">
            <div class="app_default_button cancel_outline_view" @click="$modal.hide('edit-group-contact-modal')">Cancel
            </div>

          </div>
          <!-- <b-button variant="outline-success" @click="saveUpdatedContact">Save</b-button> -->
          <div class="app_default_button success_outline_view">
            <span>Save</span>
          </div>
        </div>

      </div>

    </modal>

    <!-- tag modal -->
    <modal name="edit-folder-modal" :width="300" height="auto" draggable=".modal-header">
      <div class="modal-header">
        <span>Edit Folder</span>
        <i class="mdi mdi-close-thick" @click="$modal.hide('edit-folder-modal')"></i>
      </div>

      <div class="modal-body-view">
        <div>
          <label class="col-form-label" for="folder_name">Folder Name</label>
          <input id="folder_name" type="text" class="form-control" name="folder_name" v-model="folder_name" />
        </div>

        <div class="contact-footer-view">
          <div class="contact-footer-view-item">
            <div class="app_default_button cancel_outline_view" @click="$modal.hide('edit-folder-modal')">Cancel</div>

          </div>
          <!-- <b-button variant="outline-success" @click="saveUpdatedContact">Save</b-button> -->
          <div class="app_default_button success_outline_view" @click="updateFolder">
            <span>Save</span>
          </div>
        </div>
      </div>

    </modal>
    <!--  -->
    <div class="ctx-email-app-container">
      <!-- <div class="ctx-email-app-side-bar"></div> -->
      <div class="ctx-email-app-main-content">
        <APPNavbar />
        <!-- body view started -->
        <div class="ctx-email-app-body-content-box" v-if="enableView">
          <div class="sidebar">

            <div class="contact-table-view">
              <div class="item" :class="active_tab === 1 ? 'active_view' : ''" @click="enableTab(1)">
                <span>Contacts</span>
              </div>
              <div class="item" :class="active_tab === 2 ? 'active_view' : ''" @click="enableTab(2)">Groups</div>
            </div>

            <div class="contact-preview-header" v-if="active_tab === 1">
              <!-- <span>Contact List</span> -->
              <div class="icon-view-box">
                <b-tooltip target="add-contact-tooltip">Add Contact</b-tooltip>
                <i class="fas fa-user-plus" id="add-contact-tooltip" style="color:#f59e0b" @click="addContactModal"></i>

                <b-tooltip target="add-tag-tooltip">add tags</b-tooltip>
                <i class="fas fa-tags" id="add-tag-tooltip" style="color:#6da9fc" @click="addTagModal"></i>
                <b-tooltip target="sort-last-activity-tooltip">sort by last activity</b-tooltip>
                <i class="far fa-clock" id="sort-last-activity-tooltip" style="color:#ef4444"
                  @click="sortContactLastActivities"></i>
                <!-- <b-tooltip target="sort-tooltip">sort contact asc/desc</b-tooltip>
                <i class="fas fa-sort-amount-down" id="sort-tooltip" style="color:#6da9fc"
                  @click="sortContactAscDesc"></i> -->
                <b-tooltip target="sort-a-tooltip">sort alphabetical</b-tooltip>
                <i class="fas fa-sort-alpha-up" id="sort-a-tooltip" style="color:#1ee0ac" @click="sortContactAlpha"></i>
                <b-tooltip target="sort-refresh-tooltip">reload contacts</b-tooltip>
                <i class=" fas fa-sync" id="sort-refresh-tooltip" @click="reloadContacts" style="color:#836dff"></i>
                <b-tooltip target="search-contact">search Contact</b-tooltip>
                <i class="fa fa-search" id="search-contact" style="color:#ff63a5" @click="showSearchBox"></i>
              </div>
            </div>


            <div class="contact-preview-header" v-if="active_tab === 2">
              <!-- <span>Contact List</span> -->
              <div class="icon-view-box">

                <b-tooltip target="add-tag-tooltip">add tags</b-tooltip>
                <i class="fas fa-tags" id="add-tag-tooltip" style="color:#6da9fc" @click="addTagModal"></i>
                <b-tooltip target="sort-last-activity-tooltip">sort by last activity</b-tooltip>
                <i class="far fa-clock" id="sort-last-activity-tooltip" style="color:#ef4444"
                  @click="sortGroupContactLastActivities"></i>
                <!-- <b-tooltip target="sort-tooltip">sort contact asc/desc</b-tooltip>
                <i class="fas fa-sort-amount-down" id="sort-tooltip" style="color:#6da9fc"
                  @click="sortContactAscDesc"></i> -->
                <b-tooltip target="sort-a-tooltip">sort alphabetical</b-tooltip>
                <i class="fas fa-sort-alpha-up" id="sort-a-tooltip" style="color:#1ee0ac"
                  @click="sortGroupContactAlpha"></i>
                <b-tooltip target="sort-refresh-tooltip">reload contacts</b-tooltip>
                <i class=" fas fa-sync" id="sort-refresh-tooltip" @click="reloadContacts" style="color:#836dff"></i>
                <b-tooltip target="search-contact">search Contact</b-tooltip>
                <i class="fa fa-search" id="search-contact" style="color:#ff63a5" @click="showGroupSearchBox"></i>
              </div>
            </div>

            <!-- search box start -->
            <div class="contact_search_input_box" v-if="is_show_search_box">
              <input placeholder="search here..." v-model="search_contact" @input="searchContact" />
              <div class="close_box" @click="hideSearchBox">
                <i class="dripicons-cross"></i>
              </div>
            </div>
            <!-- serach box end -->

            <!-- search box start -->
            <div class="contact_search_input_box" v-if="is_show_group_search_box">
              <input placeholder="search here..." v-model="search_contact" @input="searchGroupContact" />
              <div class="close_box" @click="hideSearchBox">
                <i class="dripicons-cross"></i>
              </div>
            </div>
            <!-- serach box end -->
            <!-- contact list view start -->

            <div class="unified_contact_list" v-if="active_tab === 1" v-scroll-loadmore='loadMore'>
              <EmptySearch v-if="email_contact_data && email_contact_data.length === 0" />
              <!-- contact list view start -->
              <div class="unified_contact_list_main_container" v-for="(item, index) in email_contact_data" :key="index">
                <div class="contact_list_box" :class="active_email_id === item.id ? 'is_contact_active' : ''"
                  @contextmenu.prevent="onRightContactClick(item.id)">
                  <div class="avatar-initial" :style="{ background: getRandomColor(item.display_name) + '!important' }">
                    {{
                    getInitials(item.display_name)
                    }}
                  </div>
                  <div class="contact-list-info" @click.stop="showEmailMessages(item)">
                    <div class="contact-list-info-content">
                      <span class="contact">{{ item.display_name | setTruncate(20,
                        "...")

                        }}</span>
                    </div>
                    <div class="notification_badge_view2" v-if="item.unread_messages !== 0">
                      <span>{{ item.unread_messages }}</span>
                    </div>

                    <div class="sub-contact-menu" v-click-outside="onOutsideContactMenu"
                      v-if="is_contact_menu_id === item.id">
                      <div class="menu-item-box-view" @click="editContactData(item)">Edit</div>
                      <div class="menu-item-box-view" @click="deleteContactData(item.id)">Delete</div>
                      <div class="menu-item-box-view" @click="addTagsToContactsModal">Tags</div>
                      <div class="menu-item-box-view" @click="sendEmailWithSelectContact(item.contact)">New Message
                      </div>
                      <div class="menu-item-box-view" @click="addFolderModal(item)">Create Folder</div>

                    </div>
                  </div>
                </div>

                <!-- preview folder -->
                <div class="pl-3 pr-3" style="z-index: 9999 !important; position: relative" v-if="active_email_id === item.id">
                  <Folders v-if="item.folders && item.folders[0].model_id === item.id" :folder="folders"/>
                  <!-- <div class="folder_view_card" v-for="(folder, index) in item.folders" :key="index"
                    @drop="onDrop($event, folder)" @dragover.prevent @dragenter.prevent>
                    <div class="icon-view"
                      :class="folder_dropdown_ids.filter(x => x === folder.id)[0] === folder.id ? 'is_icon_dropdown' : ''"
                      @click="activateFolderDropdown(folder.id)"><i class="mdi mdi-chevron-right"></i>
                    </div>

                    <div class="folder__image__view">
                      <div class="main_folder_view" @click.stop="loadFolders('contact', folder, true, folder.id)"
                        @contextmenu.prevent="onRightFolderClick(folder.id)">
                        <img :src="folderImg" alt="folder" />
                        <span> {{ folder.name | setTruncate(13,
                          "...")

                          }}</span>
                      </div>
                      <div class="dropdown__menu" v-click-outside="onClickOutsideFolderMenu"
                        v-if="folder_menu_dropdown_id === folder.id">
                        <div class="menu_item" @click.stop="addFolderModal(item, folder.id)">
                          <span>Add</span>
                        </div>
                        <div class="menu_item" @click.stop="showEditFolder(folder)">

                          <span>Rename</span>
                        </div>
                        <div class="menu_item" @click.stop="deleteFolderData(folder.id)">

                          <span>Delete</span>
                        </div>
                      </div>
                      <div class="sub_folders_view" v-for="(sub, index) in read_folders['folder_' + folder.id]"
                        :key="index"
                        @drop="onDrop($event, sub)" @dragover.prevent @dragenter.prevent
                        >

                        <div class="sub_folder_item" @click.stop="loadFolders('contact', sub, false, folder.id)"
                          @contextmenu.prevent="onRightFolderClick(sub.id)"
                          v-if="folder_dropdown_ids.filter(x => x === folder.id)[0] === folder.id">
                          <div class="icon-view" @click.stop="activateFolderDropdown(sub.id)"
                            :class="folder_dropdown_ids.filter(x => x === sub.id)[0] === sub.id ? 'is_icon_dropdown' : ''"
                            v-if="folder_dropdown_ids.filter(x => x === folder.id)[0] === folder.id"><i
                              class="mdi mdi-chevron-right"></i>
                          </div>
                          <img :src="folderImg" alt="folder" />
                          <span>{{ sub.name }}</span>
                        </div>


                        <div class="dropdown__menu" v-click-outside="onClickOutsideFolderMenu"
                          v-if="folder_menu_dropdown_id === sub.id">
                          <div class="menu_item" @click.stop="addFolderModal(item, sub.id)">
                            <span>Add</span>
                          </div>
                          <div class="menu_item" @click.stop="showEditFolder(sub)">

                            <span>Rename</span>
                          </div>
                          <div class="menu_item" @click.stop="deleteFolderData(sub.id)">

                            <span>Delete</span>
                          </div>
                        </div>
                        <SubFolder :sub_folder="sub.sub" :folder_dropdown_ids="folder_dropdown_ids"
                          :activateFolderDropdown="activateFolderDropdown" :loadFolders="loadFolders"
                          :folderImg="folderImg" :onClickOutsideFolderMenu="onClickOutsideFolderMenu"
                          :folder_menu_dropdown_id="folder_menu_dropdown_id" :addFolderModal="addFolderModal"
                          :showEditFolder="showEditFolder" :deleteFolderData="deleteFolderData" :folder="folder"
                          :onRightFolderClick="onRightFolderClick" :item="item" 
                          />
                      </div>
                    </div>
                  </div> -->
                </div>
              </div>

              <!-- contact list view end -->



            </div>

            <!-- contact list view start -->
            <div class="unified_contact_list" v-if="active_tab === 2">
              <!-- contact list view start -->
              <div class="contact_list_box" :class="active_group_email_id === item.id ? 'is_contact_active' : ''"
                v-for="(item, index) in email_group_contact_data" :key="index"
                @contextmenu.prevent="onRightContactClick(item.id)">
                <div class="contact_avatar_group_view">
                  <!-- {{ firstGroupData(item) }} -->
                  <div class="avatar-initial-group" @click="showGroupMessages(item)">

                    {{ firstGroupData(item) }}
                  </div>
                  <div class="avatar-initial-group avatar-initial-group-2" @click="showGroupMessages(item)">
                    {{ secondGroupData(item) }}
                  </div>
                </div>
                <div class="contact-list-info">
                  <div class="contact-list-info-content" @click="showGroupMessages(item)">
                    <span class="contact group-text"> {{ item.name | setTruncate(25,
                      "...")

                      }}</span>
                    <!-- <span class="email group-text">{{ 'kbpayal.2019@gmail.com, kali@gmail.com' | setTruncate(26,
                      "...")
                      }}</span> -->
                  </div>

                  <div class="notification_badge_view2" v-if="item.unread_messages !== 0">
                    <span>{{ item.unread_messages }}</span>
                  </div>

                  <div class="sub-contact-menu" v-click-outside="onOutsideContactMenu"
                    v-if="is_contact_menu_id === item.id">
                    <div class="menu-item-box-view" @click="editContactData(item)">Edit</div>
                    <div class="menu-item-box-view" @click="deleteContactData(item.id)">Delete</div>
                    <div class="menu-item-box-view" @click="addTagsToContactsModal">Tags</div>
                    <div class="menu-item-box-view" @click="sendEmailWithSelectContact(item.contact)">New Message</div>
                    <div class="menu-item-box-view" @click="addFolderModal(item)">Create Folder</div>

                  </div>

                </div>

                <!-- preview folder -->
                <div class="pl-3 pr-3" v-if="active_email_id === item.id">
                  <div class="folder_view_card" v-for="(folder, index) in item.folders" :key="index"
                    @drop="onDrop($event, folder)" @dragover.prevent @dragenter.prevent
                    @click="activateFolderDropdown(folder.id)">
                    <div class="icon-view" :class="folder_id === folder.id ? 'is_icon_dropdown' : ''"><i
                        class="mdi mdi-chevron-right"></i>
                    </div>

                    <div class="folder__image__view" @click="loadFolderEmails(folder)">
                      <div class="main_folder_view" @click="loadFolders('contact', folder)"
                        @contextmenu.prevent="onRightFolderClick(folder.id)">
                        <img :src="folderImg" alt="folder" />
                        <span> {{ folder.name | setTruncate(13,
                          "...")

                          }}</span>
                      </div>
                      <div class="dropdown__menu" v-click-outside="onClickOutsideFolderMenu"
                        v-if="folder_menu_dropdown_id === folder.id">
                        <div class="menu_item" @click="addFolderModal(item, folder.id)">
                          <span>Add</span>
                        </div>
                        <div class="menu_item" @click="showEditFolder(folder)">

                          <span>Rename</span>
                        </div>
                        <div class="menu_item" @click="deleteFolderData(folder.id)">

                          <span>Delete</span>
                        </div>
                      </div>

                      <div class="sub_folders_view" v-for="(sub, index) in read_folders" :key="index"
                      @drop="onDrop($event, folder)" @dragover.prevent @dragenter.prevent
                      >
                        <div class="sub_folder_item" @click.stop="loadFolderEmails(sub)"
                          @contextmenu.prevent="onRightFolderClick(sub.id)"
                          v-if="folder_id === folder.id && folder_id === sub.parent_id">
                          <img :src="folderImg" alt="folder" />
                          <span>{{ sub.name }}</span>
                        </div>


                        <div class="dropdown__menu" v-click-outside="onClickOutsideFolderMenu"
                          v-if="folder_menu_dropdown_id === sub.id">
                          <div class="menu_item" @click="addFolderModal(item, sub.id)">
                            <span>Add</span>
                          </div>
                          <div class="menu_item" @click="showEditFolder(sub)">

                            <span>Rename</span>
                          </div>
                          <div class="menu_item" @click="deleteFolderData(sub.id)">

                            <span>Delete</span>
                          </div>
                        </div>
                      </div>
                    </div>

                    <!-- <div class="folder__actions"> -->

                    <!-- </div> -->
                  </div>
                </div>
              </div>
              <!-- contact list view end -->



            </div>

          </div>
          <div class="body">
            <div class="body-top-bar">
              <!-- <div class="compose-box" @click="showCompose">
                <div class="compose-box-icon">
                  <i class="mdi mdi-plus"></i>
                </div>
                <span>Compose Mail</span>
              </div> -->
              <div></div>
              <div class="read-later-box-view">
                <!-- <div class="read-later-tab" :class="is_read_active == 1 ? 'read_active' : ''" @click="activeReadTab(1)"
                  v-if="paginatedEmailData && paginatedEmailData.length > 0">
                  <span><i class="fas fa-inbox " style="color:#6da9fc !important"></i> Mails </span>
                </div> -->
                <div class="read-later-tab dropdown-tab-view"
                  :class="is_read_active == 2 || is_read_active == 3 || is_read_active == 4 ? 'read_active' : ''">
                  <!-- <span v-if="is_read_active === 2"><i class="fas fa-clock" style="color:#6da9fc !important"></i> Read
                    Later</span>
                  <span v-else-if="is_read_active === 3"><i class="fas fa-clock" style="color:#6da9fc !important"></i>
                    Reply Later</span>
                  <span v-else-if="is_read_active === 4"><i class="fas fa-clock" style="color:#6da9fc !important"></i>
                    Write Later</span> -->
                  <span><i class="fas fa-clock" style="color:#6da9fc !important"></i> The Later List</span>
                  <div class="drop-down-list">
                    <div class="list-box" @click="activeReadTab(2)">
                      <i class="fas fa-clock" style="color:#6da9fc !important"></i>
                      <span>The Read Later List</span>
                    </div>
                    <div class="list-box" @click="activeReadTab(3)">
                      <i class="fas fa-clock" style="color:#6da9fc !important"></i>
                      <span>The Reply Later List</span>
                    </div>

                    <div class="list-box" @click="activeReadTab(4)">
                      <i class="mdi mdi-file-document-edit-outline" style="color:#6da9fc !important"></i>
                      <span>The Write Later List</span>
                    </div>
                  </div>
                </div>
                <!-- <div class="reply-later-tab" :class="is_read_active == 3 ? 'read_active' : ''"
                  @click="activeReadTab(3)">
                  <span><i class="fas fa-reply-all" style="color:#6da9fc !important"></i> Reply Later</span>
                </div>
                <div class="reply-later-tab" :class="is_read_active == 4 ? 'read_active' : ''"
                  @click="activeReadTab(4)">
                  <span><i class="mdi mdi-file-document-edit-outline" style="color:#6da9fc !important"></i> Write
                    Later</span>
                </div> -->

              </div>
              <div class="icon-view-box">
                <!-- <b-tooltip target="add-tag-tooltip">add tag</b-tooltip>
                     <i class="fas fa-tags" id="add-tag-tooltip"></i> -->
                <div>
                  <b-tooltip target="sort-m-tooltip">sort message asc/desc</b-tooltip>
                  <i class="fas fa-sort-amount-down" id="sort-m-tooltip" style="color:#6da9fc"
                    @click="sortEmailMessage"></i>
                </div>

                <b-tooltip target="sort-m-a-tooltip">sort message alphabetical</b-tooltip>
                <i class="fas fa-sort-alpha-up" id="sort-m-a-tooltip" style="color:#1ee0ac"
                  @click="sortEmailMessageAlpha"></i>
               
                <i class=" fas fa-sync" id="sort-m-refresh-tooltip" @click="refreshMessage" style="color:#836dff">
                  <b-tooltip target="sort-m-refresh-tooltip">reload messages</b-tooltip></i>
                <b-tooltip target="search-m-contact">search Contact</b-tooltip>
                <i class="fa fa-search" id="search-m-contact" style="color:#ff63a5" @click="showSearchMessageBox"></i>
              </div>
            </div>

            <div class="search_message_box" v-if="is_search_message_box">
              <input placeholder="search here..." v-model="search_message" @input="searchEmailMessage" />
              <div class="close_box" @click="hideSearchMessageBox">
                <i class="dripicons-cross"></i>
              </div>
            </div>

            <!-- message box view start -->
            <div class="message_inbox_preview">
              <!-- email inbox preview start -->
              <div class="ctx-message-app-view-main">

                <EmptyData title="No message"
                  v-if="paginatedEmailData && paginatedEmailData.length === 0 && single_communication_channel.id !== undefined" />


                <EmptyData title="Select a channel to view messages"
                  v-if="paginatedEmailData && paginatedEmailData.length === 0 && single_communication_channel.id === undefined" />


                <div :id="email.id" class="ctx-message-app-view" v-for="(email, index) in paginatedEmailData"
                  :key="index" draggable @dragstart="onDragStart($event, email)">
                  <div class="check-box"><input type="checkbox" :id="email.id" autocomplete='off'
                      @change="checkEmails(email.id)" /></div>
                  <div class="icons">
                    <i class=" far fa-envelope-open" style="color: #0096FF !important;"
                      v-if="single_communication_channel.email_account && single_communication_channel.email_account.username !== email.from[0].Email"></i>
                    <i class="far fa-paper-plane sent" style="color:#B5A642 !important;"
                      v-if="single_communication_channel.email_account && single_communication_channel.email_account.username === email.from[0].Email"></i>
                    <i class="fa fa-paperclip attachment" style="color: #D4D4D4 !important"
                      v-if="email.attachments && email.attachments.length > 0"></i>
                  </div>
                  <div class="message-preview">
                    <span class="subject" @click="showMessage(email)">{{ email.subject && email.subject.length > 0 ?
                      email.subject : "(No subject)"

                      }}</span>
                    <span class="body" @click="showMessage(email)">{{ email.snippet !== undefined ? email.snippet : '' |
                      setTruncate(body_string,
                      "...")

                      }}</span>
                  </div>
                  <div class="date">
                    <span>{{ date(email.date) }}</span>

                  </div>
                </div>
              </div>
              <!-- email inbox preview end -->


              <!-- message box view start -->
              <!-- write later preveiw shows -->
              <modal name="write-later-modal" :width="1250" height="80%" draggable=".modal-header">

                <div class="modal-item">
                  <div class="modal-header">
                    <span>The Write Later List</span>
                    <i class="mdi mdi-close-thick" @click="$modal.hide('write-later-modal')"></i>
                  </div>

                  <div class="mt-5" v-if="write_later_data && write_later_data.length === 0">
                    <EmptyData title="No Write Later Message" />
                  </div>

                  <!-- read later preivew end -->
                  <div class="ctx-message-app-view-main" v-if="emails_view_type === 'write_later'">
                    <div class="ctx-message-app-view" v-for="(email, index) in write_later_data" :key="index">
                      <div class="check-box"><input type="checkbox" :id="email.id" autocomplete='off'
                          @change="checkEmails(email.id)" /></div>
                      <div class="icons">
                        <i class="mdi mdi-email-open-outline incoming"
                          v-if="single_communication_channel.email_account && single_communication_channel.email_account.username !== email.from[0].Email"></i>
                        <i class="far fa-paper-plane sent"
                          v-if="single_communication_channel.email_account && single_communication_channel.email_account.username === email.from[0].Email"></i>
                        <i class="fa fa-paperclip attachment"
                          v-if="email.attachments && email.attachments.length > 0"></i>
                      </div>
                      <div class="message-preview">
                        <span class="subject" @click="showWriteLaterCompose(email)">{{ email.subject &&
                          email.subject.length
                          > 0 ?
                          email.subject : "(No subject)"

                          }}</span>
                        <span class="body" @click="showWriteLaterCompose(email)">{{ email.snippet !== undefined ?
                          email.snippet : '' |
                          setTruncate(body_string,
                          "...")

                          }}</span>
                      </div>
                      <div class="date">
                        <span>{{ date(email.date) }}</span>
                        
                      </div>
                     
                      <div class="remove" @click="deleteWroteLater(email.id)">
                        <i class="mdi mdi-trash-can-outline" id="delete-later-list-tooltip" style="color:#1ee0ac"></i>
                      </div>
                      <!-- <b-tooltip target="delete-later-list-tooltip">Remove from list</b-tooltip> -->
                    </div>
                  </div>
                  <!-- email inbox preview end -->
                </div>
              </modal>
              <!-- write later preivew end -->

              <!-- reply later preveiw shows -->
              <modal name="reply-later-modal" :width="1200" height="80%" draggable=".modal-header">

                <div class="modal-item">
                  <div class="modal-header">
                    <span>The Reply Later List</span>
                    <i class="mdi mdi-close-thick" @click="$modal.hide('reply-later-modal')"></i>
                  </div>
                  <div class="mt-5" v-if="reply_later_data && reply_later_data.length === 0">
                    <EmptyData title="No Reply Later Messages" />
                  </div>

                  <!-- email reply later preview start -->
                  <div class="ctx-message-app-view-main" v-if="emails_view_type === 'reply_later'">
                    <div class="ctx-message-app-view" v-for="(email, index) in reply_later_data" :key="index">
                      <div class="check-box"><input type="checkbox" :id="email.email_message.id" autocomplete='off'
                          @change="checkEmails(email.email_message.id)" /></div>
                      <div class="icons">
                        <i class="mdi mdi-email-open-outline incoming"
                          v-if="single_communication_channel.email_account && single_communication_channel.email_account.username !== email.email_message.from[0].Email"></i>
                        <i class="far fa-paper-plane sent"
                          v-if="single_communication_channel.email_account && single_communication_channel.email_account.username === email.email_message.from[0].Email"></i>
                        <i class="fa fa-paperclip attachment"
                          v-if="email.email_message.attachments && email.email_message.attachments.length > 0"></i>
                      </div>
                      <div class="message-preview">
                        <span class="subject" @click="showReplyLaterMessage(email.email_message)">{{
                          email.email_message.subject && email.email_message.subject.length > 0 ?
                          email.email_message.subject : "(No subject)"

                          }}</span>
                        <span class="body" @click="showReplyLaterMessage(email.email_message)">{{
                          email.email_message.snippet !== undefined ? email.email_message.snippet : '' |
                          setTruncate(body_string,
                          "...")

                          }}</span>
                      </div>
                      <div class="date">
                        <span>{{ date(email.email_message.date) }}</span>

                      </div>
                      <div class="remove" @click="deleteReplyLater(email.email_message.id)">
                        <i class="mdi mdi-trash-can-outline" id="delete-later-list-tooltip" style="color:#1ee0ac"></i>
                      </div>
                    </div>
                  </div>
                  <!-- email reply later preview end -->
                </div>
              </modal>

              <modal name="email-reply-message-modal" :width="1000" height="auto" draggable=".modal-header">
                <div class="modal-container">
                  <div class="modal-header">
                    <span>{{ single_communication_channel.title }} - {{ single_communication_channel.email_account ===
                      undefined ?
                      '' : single_communication_channel.email_account && single_communication_channel.email_account.username
                      }} </span>
                    <i class="mdi mdi-close-thick" @click="$modal.hide('email-reply-message-modal')"></i>
                  </div>
                  <div class="modal-body-view">
                    <ReadReplyLaterMail v-if="is_reply_later_details" :hideDetails="hideDetails"
                      :showEmail="showReplyLaterMessage" :email_details="email_reply_later_details"
                      :email_account_id="this.email_item_account_id" />
                  </div>
                </div>

              </modal>
              <!-- reply later preivew end -->

              <!-- read later preveiw shows -->
              <modal name="read-later-modal" :width="1200" height="80%" draggable=".modal-header">

                <div class="modal-item">
                  <div class="modal-header">
                    <span>The Read Later List</span>
                    <i class="mdi mdi-close-thick" @click="$modal.hide('read-later-modal')"></i>
                  </div>
                  <div class="mt-5" v-if="read_later_data && read_later_data.length === 0">
                    <EmptyData title="No Read Later Messages" />
                  </div>
                  <!-- email read later preview start -->
                  <div class="ctx-message-app-view-main" v-if="emails_view_type === 'read_later'">
                    <div class="ctx-message-app-view" v-for="(email, index) in read_later_data" :key="index">
                      <div class="check-box"><input type="checkbox" :id="email.email_message.id" autocomplete='off'
                          @change="checkEmails(email.email_message.id)" /></div>
                      <div class="icons">
                        <i class="mdi mdi-email-open-outline incoming"
                          v-if="single_communication_channel.email_account && single_communication_channel.email_account.username !== email.email_message.from[0].Email"></i>
                        <i class="far fa-paper-plane sent"
                          v-if="single_communication_channel.email_account && single_communication_channel.email_account.username === email.email_message.from[0].Email"></i>
                        <i class="fa fa-paperclip attachment" style="color: #8094ae9d !important;"
                          v-if="email.email_message.attachments && email.email_message.attachments.length > 0"></i>
                      </div>
                      <div class="message-preview">
                        <span class="subject" @click="showReadLaterMessage(email.email_message)">{{
                          email.email_message.subject && email.email_message.subject.length > 0 ?
                          email.email_message.subject : "(No subject)"

                          }}</span>
                        <span class="body" @click="showReadLaterMessage(email.email_message)">{{
                          email.email_message.snippet
                          !== undefined ? email.email_message.snippet : '' |
                          setTruncate(body_string,
                          "...")

                          }}</span>
                      </div>
                      <div class="date">
                        <span>{{ date(email.email_message.date) }}</span>
                  
                      </div>
                      <div class="remove" @click="deleteReadLater(email.email_message.id)">
                        <i class="mdi mdi-trash-can-outline" id="delete-later-list-tooltip" style="color:#1ee0ac"></i>
                      </div>
                    </div>
                  </div>

                  <modal name="email-read-message-modal" :width="1000" height="auto" draggable=".modal-header">

                    <div class="modal-header">
                      <span>{{ single_communication_channel.title }} - {{ single_communication_channel.email_account ===
                        undefined ?
                        '' : single_communication_channel.email_account.username
                        }} </span>
                      <i class="mdi mdi-close-thick" @click="$modal.hide('email-read-message-modal')"></i>
                    </div>
                    <div class="modal-body-view">
                      <ReadLaterMail v-if="is_read_later_details" :hideDetails="hideDetails"
                        :showEmail="showReadLaterMessage" :email_details="email_read_later_details"
                        :email_account_id="this.email_item_account_id" />
                    </div>

                  </modal>
                  <!-- email read later preview end -->

                </div>
              </modal>

              <!-- pagination view start -->
              <div class="text-md-right float-xl-right mt-2 pagination-rounded"
                v-if="emails_view_type === 'inbox' || emails_view_type === 'write_later'">
                <b-pagination v-model="currentPage" :total-rows="total_rows" :per-page="perPage" @input="onPageChange">
                </b-pagination>
              </div>
              <!-- pagination view ends -->
            </div>

            <!-- ,emssage box view end -->
          </div>
          <!-- v-if="is_contact_details" -->
          <div class="contact-details-view" v-if="is_contact_details">
            <div class="user_profile_view">
              <div class="user_profile_avatal">
                {{ getInitials(emailDetails.display_name) || getInitials(emailDetails.name) }}
              </div>
              <span class="user_profile_name">{{ emailDetails.display_name || emailDetails.name }}</span>
              <span class="user_profile_email">{{ emailDetails.contact }}</span>
              <div class="tag-box-view">
                <div class="tag-details-view" v-for="(item, index) in emailDetails.tags" :key="index">
                  <span class="tag-name">{{ item.value }}</span>
                  <span class="delete-tag" @click="deleteTags(item.id)"><i class="fas fa-trash"></i></span>
                </div>
              </div>
            </div>
            <div class="user_profile_accordion">
              <div role="tablist">
                <b-card no-body class="mb-1 shadow-none">
                  <b-card-header header-tag="header" role="tab">
                    <h6 class="m-0">
                      <a v-b-toggle.options class="text-dark" href="javascript: void(0);">Options</a>
                    </h6>
                  </b-card-header>
                  <b-collapse id="options" visible accordion="app-accordion" role="tabpanel">
                    <b-card-body>
                      <b-card-text>
                        <div class="menu-item" @click="editContactData(emailDetails)">
                          <div class="icon-box" style="background:#e6ecf6 !important"><i
                              class="mdi mdi-account-edit-outline" style="color:#6da9fc"></i></div>
                          <span class="item-text">Edit</span>
                        </div>

                        <div class="menu-item" @click="deleteContactData(emailDetails.id)">
                          <div class="icon-box" style="background:#e8fcf7 !important"><i
                              class="mdi mdi-trash-can-outline" style="color:#1ee0ac"></i></div>
                          <span class="item-text">Delete</span>
                        </div>

                        <div class="menu-item" @click="addTagsToContactsModal">
                          <div class="icon-box" style="background:#f2f0ff !important"><i
                              class="mdi mdi-tag-multiple-outline" style="color:#836dff"></i></div>
                          <span class="item-text">Tags</span>
                        </div>

                        <div class="menu-item" @click="sendEmailWithSelectContact(emailDetails.contact)">
                          <div class="icon-box" style="background:#ffeff6 !important"><i
                              class="mdi mdi-file-document-edit-outline" style="color:#ff63a5"></i></div>
                          <span class="item-text">New Message</span>
                        </div>
                      </b-card-text>
                    </b-card-body>
                  </b-collapse>
                </b-card>
              </div>
            </div>
          </div>

          <!-- v-if="is_contact_details" -->
          <div class="contact-details-view" v-if="is_group_view">
            <div class="user_profile_view">
              <div class="user_profile_avatal">
                {{ getInitials(emailDetails.name) }}
              </div>
              <span class="user_profile_name">{{ emailDetails.name }}</span>
              <span class="user_profile_email">{{ emailDetails.contact }}</span>
              <div class="tag-box-view">
                <div class="tag-details-view" v-for="(item, index) in emailDetails.tags" :key="index">
                  <span class="tag-name">{{ item.value }}</span>
                  <span class="delete-tag" @click="deleteTags(item.id)"><i class="fas fa-trash"></i></span>
                </div>
              </div>
            </div>
            <div class="user_profile_accordion">
              <div role="tablist">
                <b-card no-body class="mb-1 shadow-none">
                  <b-card-header header-tag="header" role="tab">
                    <h6 class="m-0">
                      <a v-b-toggle.options class="text-dark" href="javascript: void(0);">Options</a>
                    </h6>
                  </b-card-header>
                  <b-collapse id="options" visible accordion="app-accordion" role="tabpanel">
                    <b-card-body>
                      <b-card-text>
                        <div class="menu-item" @click="editGroupContactData(emailDetails)">
                          <div class="icon-box" style="background:#e6ecf6 !important"><i
                              class="mdi mdi-account-edit-outline" style="color:#6da9fc"></i></div>
                          <span class="item-text">Edit</span>
                        </div>

                        <div class="menu-item" @click="deleteContactData(emailDetails.id)">
                          <div class="icon-box" style="background:#e8fcf7 !important"><i
                              class="mdi mdi-trash-can-outline" style="color:#1ee0ac"></i></div>
                          <span class="item-text">Delete</span>
                        </div>

                        <div class="menu-item" @click="addTagsToContactsModal">
                          <div class="icon-box" style="background:#f2f0ff !important"><i
                              class="mdi mdi-tag-multiple-outline" style="color:#836dff"></i></div>
                          <span class="item-text">Tags</span>
                        </div>

                        <div class="menu-item" @click="sendEmailWithSelectContact(emailDetails.group_members)">
                          <div class="icon-box" style="background:#ffeff6 !important"><i
                              class="mdi mdi-file-document-edit-outline" style="color:#ff63a5"></i></div>
                          <span class="item-text">New Message</span>
                        </div>
                      </b-card-text>
                    </b-card-body>
                  </b-collapse>
                </b-card>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>

    <!-- tag modal -->
    <modal name="compose-modal" :width="800" height="auto" draggable=".modal-header">
      <div class="split-compose-view">
        <ComposeEmail v-for="(item, index) in composeView" :channel="single_communication_channel" :key="index" :item="item" :removeMailBox="removeMailBox"
          :enableCc="enableCc" :enableBcc="enableBcc" />
      </div>
    </modal>

  </div>
</template>

<script>
const toBase64 = file => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file.file);
  reader.onload = () => resolve(reader.result);
  reader.onerror = error => reject(error);
});

import moment from 'moment';
import APPNavbar from '../../../components/app-topbar.vue'
import { communicationChannelComputed, communicationChannelMethods } from '../../../state/helpers/communicationchannel';
import { emailAccountComputed, emailAccountMethods } from '../../../state/helpers/eaccount';
import { emailNotificationMethods } from '../../../state/helpers/emailnotification';
import { inboxComputed, inboxMethods } from '../../../state/helpers/inbox';
import Swal from "sweetalert2";
import { uid } from "uid";
import ComposeEmail from "../../../components/email/compose.vue";
import ReadMail from "../../../components/email/reademail.vue";
import ReadReplyLaterMail from "../../../components/email/replylateremaildetails.vue";
import ReadLaterMail from "../../../components/email/readlateremaildetails.vue";
import GroupLaterMail from "../../../components/email/groupreademail.vue";
import { convertHtmlToStringMixing } from "../../../utils/converthtml";
import folderImg from "../../../assets/images/folder.svg"
import EmptyData from "../../../components/empty.vue";
import EmptySearch from "../../../components/emptysearch.vue";
import { layoutComputed } from '../../../state/helpers';
import Folders from '../../../components/folder/folders.vue';

export default {
  components: {
    APPNavbar,
    ComposeEmail,
    ReadMail,
    ReadReplyLaterMail,
    ReadLaterMail,
    GroupLaterMail,
    EmptyData,
    EmptySearch,
    Folders,
},
  mixins: [convertHtmlToStringMixing],
  data() {
    return {
      active_tab: 1,
      is_contact_details: false,
      folderImg,
      // page number
      currentPage: 1,
      text: `
        voluptate nisi qui.
        `,
      content: `Food truc`,
      // default page size
      body_string: 100,
      title_string: 80,
      // show_view: false,
      // start and end index
      startIndex: 1,
      perPage: 10,
      endIndex: 10,
      showModal: false,
      editorData: "",
      account_color: null,
      account_title: "Email Accounts",
      // email_contact_data: [],
      active_email_id: null,
      is_details: false,
      email_details: {},
      composeView: [],
      email_item_account_id: null,
      is_star: false,
      search_contact: "",
      is_show_search_box: false,
      message_search_box: false,
      search_message: "",
      is_search_message_box: false,
      email_search: "",
      date_search: "",
      active_email: "",
      check_emails: [],
      active_account: null,
      is_contact_search: false,
      is_message_search: false,
      active_contact_box: null,
      rows: 1,
      contact_details: {},
      contact: "",
      display_name: "",
      emailDetails: {},
      accounts: null,
      folder_name: "",
      is_read_active: 1,
      sort_contact_type: "",
      sort_email_type: "",
      tag_names: [],
      contact_tags: [],
      emails_view_type: "inbox",
      is_reply_later_details: false,
      email_reply_later_details: {},
      is_read_later_details: false,
      email_read_later_details: {},
      fileRecords: [],
      uploadUrl: "https://www.mocky.io/v2/5d4fb20b3000005c111099e3",
      uploadHeaders: { "X-Test-Header": "vue-file-agent" },
      fileRecordsForUpload: [], // maintain an upload queue
      photo: null,
      first_name: '',
      last_name: '',
      middle_name: '',
      facebook: '',
      instagram: '',
      linked_in: '',
      whats_app: '',
      skype: '',
      telegram: '',
      cxm: '',
      cxv: '',
      phone_number: '',
      phone_number_two: '',
      contact_type: '',
      email: '',
      email_two: '',
      home_address: '',
      home_address_two: '',
      city: '',
      state: '',
      country: '',
      postal_code: '',
      business_address: '',
      business_address_two: '',
      business_city: '',
      business_state: '',
      business_country: '',
      business_postal_code: '',
      mailing_address: '',
      mailing_address_two: '',
      mailing_city: '',
      mailing_state: '',
      mailing_country: '',
      mailing_postal_code: '',
      active_folder_id: '',
      active_draggable_message_id: '',
      is_group_view: false,
      group_contact: '',
      active_group_email_id: '',
      folder_id: '',
      is_enable_folder: false,
      folder_menu_dropdown_id: '',
      is_group_view_enable: false,
      is_show_group_search_box: false,
      is_contact_menu_id: '',
      parent_id: null,
      folder_dropdown_ids: [],
    }
  },
  computed: {
    ...layoutComputed,
    ...emailAccountComputed,
    ...communicationChannelComputed,
    ...inboxComputed,

    enableView: function () {
      return this.enable_view;
    },
    tags: function () {
      return this.getAllTags;
    },
    email_contact_data: function () {
      return this.getEmailContacts;
    },
    email_group_contact_data: function () {
      return this.getGroupEmailContacts;
    },
    reply_later_data: function () {
      return this.getAllReplyLaterEmails;
    },
    read_later_data: function () {
      return this.getAllReadLaterEmails;
    },
    write_later_data: function () {
      return this.getAllWriteLaterEmails;
    },
    read_folders: function () {
      return this.getAllFolders;
    },
    folders: {
            get(){ return this.getAllFolders},
            set(value) {
              return value
            }
    },
    paginatedEmailData: {
      get: function () {
        return this.getAllInbox;
      },
    },
    single_communication_channel: {
      get: function () {
        return this.show_communication_channel;
      },
    },

    pages: {
      get: function () {
        return this.getTotalRows;
      },
      set: function (value) {
        return value;
      }
    },
    emailData: {
      get: function () {
        return this.getAllInbox;
      },
    },
    total_rows: {
      get: function () {
        return this.getTotalRows;
      },
    },
  },
  methods: {
    ...emailAccountMethods,
    ...emailNotificationMethods,
    ...communicationChannelMethods,
    ...inboxMethods,
    uploadFiles: function () {
      // Using the default uploader. You may use another uploader instead.
      this.$refs.vueFileAgent.upload(
        this.uploadUrl,
        this.uploadHeaders,
        this.fileRecordsForUpload
      );
      this.fileRecordsForUpload = [];
    },
    deleteUploadedFile: function (fileRecord) {
      // Using the default uploader. You may use another uploader instead.
      this.$refs.vueFileAgent.deleteUpload(
        this.uploadUrl,
        this.uploadHeaders,
        fileRecord
      );
    },
    filesSelected: function (fileRecordsNewlySelected) {

      var validFileRecords = fileRecordsNewlySelected.filter(
        (fileRecord) => !fileRecord.error
      );
      this.fileRecordsForUpload = this.fileRecordsForUpload.concat(
        validFileRecords
      );

      const files = this.fileRecordsForUpload;
      // let fileData = []; 
      files.forEach(async (file) => {
        const data = await toBase64(file);
        this.photo = { name: file.file.name, file: data };
      });
    },
    onBeforeDelete: function (fileRecord) {
      var i = this.fileRecordsForUpload.indexOf(fileRecord);
      if (i !== -1) {
        // queued file, not yet uploaded. Just remove from the arrays
        this.fileRecordsForUpload.splice(i, 1);
        var k = this.fileRecords.indexOf(fileRecord);
        if (k !== -1) this.fileRecords.splice(k, 1);
      } else {
        if (confirm("Are you sure you want to delete?")) {
          this.$refs.vueFileAgent.deleteFileRecord(fileRecord); // will trigger 'delete' event
        }
      }
    },
    fileDeleted: function (fileRecord) {
      var i = this.fileRecordsForUpload.indexOf(fileRecord);
      if (i !== -1) {
        this.fileRecordsForUpload.splice(i, 1);
      } else {
        this.deleteUploadedFile(fileRecord);
      }
    },
    enableTab(data) {
      this.active_tab = data;
      const channel_id = localStorage.getItem('email_account_id')
      let account_id;
      if(channel_id !== null){
        if(channel_id !== undefined){
          account_id = +channel_id
        }
      }else{
        account_id = this.single_communication_channel.email_account.id
      }

      const contact_data = {
        search_term: "",
        page: 1,
        per_page: this.perPage,
        account_id,
        sort_column: "",
        sort_direction: "",
      }
      if (data === 1) {
        this.emptyInbox();
        this.is_contact_details = false;
        this.is_group_view_enable = false;
        this.allEmailContact(contact_data);
      }
      if (data === 2) {
        this.emptyInbox();
        this.is_contact_details = false;
        this.allGroupContact(contact_data);
        this.is_group_view_enable = true;
      }
    },
    getInitials(nameString) {
      if (nameString === undefined) return;
      let rgx = new RegExp(/\b(\w)/g, 'gu');

      let initials = [...nameString.matchAll(rgx)] || [];

      initials = (
        (initials.shift()?.[1] || '') + (initials.pop()?.[1] || '')
      ).toUpperCase();

      return initials
    },
    addTagModal() {
      this.$modal.show("tags-modal");
    },
    activateFolderDropdown() {
      // const ids = this.folder_dropdown_ids.filter(x => x === id);
      // if (ids.length !== 0) {
      //   this.folder_dropdown_ids = this.folder_dropdown_ids.filter(x => x !== id)
      // } else {
      //   this.folder_dropdown_ids.push(id);
      // }

    },
    onRightFolderClick(id) {
      this.folder_menu_dropdown_id = id;
    },
    onRightContactClick(id) {
      this.is_contact_menu_id = id;
    },
    onClickOutsideFolderMenu() {
      this.folder_menu_dropdown_id = "";
    },
    onOutsideContactMenu() {
      this.is_contact_menu_id = "";
    },

    addFolderModal(data, id) {
      this.$modal.show("folder-modal");
      this.emailDetails = data;
      this.parent_id = id;
      this.folder_name = "";
    },
    closeContactModal() {
      this.$modal.hide("tags-modal");
      this.$modal.hide("folder-modal");
      this.$modal.hide("edit-folder-modal");
      this.$modal.hide("edit-contact-modal")
    },
    getRandomColor(name) {
      var colors = ['#798bff', '#ff63a5', '#09c2de', '#8091a7', '#1f2b3a'];
      const str = name;
      // generate an integer code from string
      // you can use the length of the string but that will not be very interesting.
      var hashCode = 0, i, chr;
      if (str.length === 0) return hashCode;
      for (i = 0; i < str.length; i++) {
        chr = str.charCodeAt(i);
        hashCode = ((hashCode << 5) - hashCode) + chr;
        hashCode |= 0;
      }
      // get index of color by calculating the modulos of the code
      const colorIndex = hashCode % colors.length

      return colors[colorIndex]
      // const color = colors[Math.floor(Math.random() * colors.length)];
    },
    activeReadTab(data) {
      this.is_read_active = data;

      if (data === 1) {
        this.emails_view_type = "inbox"
        this.showEmailMessages(this.emailDetails)
      } else if (data === 2) {
        this.loadReadLaterEmails();
        this.emails_view_type = "read_later";
        this.$modal.show("read-later-modal");
      } else if (data === 3) {

        this.loadReplyLaterEmails();
        this.emails_view_type = "reply_later"
        this.$modal.show("reply-later-modal");
      } else if (data === 4) {
        this.emails_view_type = "write_later"
        this.showWriteMessages(this.emailDetails)
        this.$modal.show("write-later-modal");
      }
    },
    showMessage(data) {
      this.$modal.show("email-message-modal");
      this.is_details = true;
      this.email_details = data;

      if (data.read === false) {
        const email = {
          id: data.id,
          status: true,
          type: 'read',
        }
        this.updateEmail(email).then(() => {
          const channel_id = localStorage.getItem('email_account_id')
      let account_id;
      if(channel_id !== null){
        if(channel_id !== undefined){
          account_id = +channel_id
        }
      }else{
        account_id = this.single_communication_channel.email_account.id
      }
          let contact_data = {
            search_term: "",
            last_contact_id: 0,
            account_id,
            limit: 24
          }
          this.allChannel();
          this.allEmailContact(contact_data)
        })
      }
      // const email_account_id = localStorage.getItem("email_account_id");
      // if(data.email_notification !== null){
      //   this.deleteEmailNotification(data.email_notification.id).then(response => {  
      //        this.allChannel();
      //        this.allEmailContact({ email_account_id: email_account_id})
      //        return response;
      //   })
      // }

    },
    showReplyLaterMessage(data) {
      this.$modal.show("email-reply-message-modal");
      this.is_reply_later_details = true;
      this.email_reply_later_details = data;
    },
    showReadLaterMessage(data) {
      this.$modal.show("email-read-message-modal");
      this.is_read_later_details = true;
      this.email_read_later_details = data;
    },

    sortContactAscDesc() {

      if (this.sort_contact_type === "" || this.sort_contact_type === "desc") {
        this.sort_contact_type = "asc"
      } else if (this.sort_contact_type === "asc") {
        this.sort_contact_type = "desc"
      }
      const channel_id = localStorage.getItem('email_account_id')
      let account_id;
      if(channel_id !== null){
        if(channel_id !== undefined){
          account_id = +channel_id
        }
      }else{
        account_id = this.single_communication_channel.email_account.id
      }
      let contact_data = {
        search_term: "",
        last_contact_id: 0,
        account_id,
        limit: 100,
        sort_column: "",
        sort_direction: this.sort_contact_type,
      }
      this.allEmailContact(contact_data)
    },
    sortGroupContactAscDesc() {

      if (this.sort_contact_type === "" || this.sort_contact_type === "desc") {
        this.sort_contact_type = "asc"
      } else if (this.sort_contact_type === "asc") {
        this.sort_contact_type = "desc"
      }
      const channel_id = localStorage.getItem('email_account_id')
      let account_id;
      if(channel_id !== null){
        if(channel_id !== undefined){
          account_id = +channel_id
        }
      }else{
        account_id = this.single_communication_channel.email_account.id
      }
      let contact_data = {
        search_term: "",
        last_contact_id: 0,
        account_id,
        limit: 100,
        sort_column: "",
        sort_direction: this.sort_contact_type,
      }
      this.allGroupContact(contact_data)
    },

    sortContactAlpha() {

      if (this.sort_contact_type === "" || this.sort_contact_type === "desc") {
        this.sort_contact_type = "asc"
      } else if (this.sort_contact_type === "asc") {
        this.sort_contact_type = "desc"
      }
      const channel_id = localStorage.getItem('email_account_id')
      let account_id;
      if(channel_id !== null){
        if(channel_id !== undefined){
          account_id = +channel_id
        }
      }else{
        account_id = this.single_communication_channel.email_account.id
      }
      let contact_data = {
        search_term: "",
        last_contact_id: 0,
        account_id,
        limit: 100,
        sort_column: "display_name",
        sort_direction: this.sort_contact_type,
      }
      this.allEmailContact(contact_data)
    },
    sortGroupContactAlpha() {

      if (this.sort_contact_type === "" || this.sort_contact_type === "desc") {
        this.sort_contact_type = "asc"
      } else if (this.sort_contact_type === "asc") {
        this.sort_contact_type = "desc"
      }
      const channel_id = localStorage.getItem('email_account_id')
      let account_id;
      if(channel_id !== null){
        if(channel_id !== undefined){
          account_id = +channel_id
        }
      }else{
        account_id = this.single_communication_channel.email_account.id
      }

      let contact_data = {
        search_term: "",
        last_contact_id: 0,
        account_id,
        limit: 100,
        sort_column: "display_name",
        sort_direction: this.sort_contact_type,
      }
      this.allGroupContact(contact_data)
    },

    sortContactLastActivities() {

      if (this.sort_contact_type === "" || this.sort_contact_type === "desc") {
        this.sort_contact_type = "asc"
      } else if (this.sort_contact_type === "asc") {
        this.sort_contact_type = "desc"
      }
      const channel_id = localStorage.getItem('email_account_id')
      let account_id;
      if(channel_id !== null){
        if(channel_id !== undefined){
          account_id = +channel_id
        }
      }else{
        account_id = this.single_communication_channel.email_account.id
      }
      let contact_data = {
        search_term: "",
        last_contact_id: 0,
        account_id,
        limit: 100,
        sort_column: "last_activity",
        sort_direction: this.sort_contact_type,
      }
      this.allEmailContact(contact_data)
    },

    sortGroupContactLastActivities() {

      if (this.sort_contact_type === "" || this.sort_contact_type === "desc") {
        this.sort_contact_type = "asc"
      } else if (this.sort_contact_type === "asc") {
        this.sort_contact_type = "desc"
      }
      const channel_id = localStorage.getItem('email_account_id')
      let account_id;
      if(channel_id !== null){
        if(channel_id !== undefined){
          account_id = +channel_id
        }
      }else{
        account_id = this.single_communication_channel.email_account.id
      }
      let contact_data = {
        search_term: "",
        last_contact_id: 0,
        account_id,
        limit: 100,
        sort_column: "last_activity",
        sort_direction: this.sort_contact_type,
      }
      this.allGroupContact(contact_data)
    },


    addContactModal() {
      this.$modal.show('add-contact-modal');
    },
    showSearchBox() {
      this.is_show_search_box = true;
    },
    showGroupSearchBox() {
      this.is_show_group_search_box = true;
    },
    hideSearchBox() {
      this.is_show_search_box = false;
      this.is_show_group_search_box = false;
      this.search_contact = "";
    },
    searchContact() {

      const email = this.search_contact.match(
        // eslint-disable-next-line no-useless-escape
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
      const channel_id = localStorage.getItem('email_account_id')
      let account_id;
      if(channel_id !== null){
        if(channel_id !== undefined){
          account_id = +channel_id
        }
      }else{
        account_id = this.single_communication_channel.email_account.id
      }
      if (email) {
        let contact_data = {
          search_term: this.search_contact,
          last_contact_id: 0,
          account_id,
          limit: 50
        }

        this.allEmailContact(contact_data)
      } else {
        let contact_data = {
          search_term: this.search_contact,
          last_contact_id: 0,
          account_id,
          limit: 50
        }

        this.allEmailContact(contact_data)
      }
    },
    searchGroupContact() {

      const email = this.search_contact.match(
        // eslint-disable-next-line no-useless-escape
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
      const channel_id = localStorage.getItem('email_account_id')
      let account_id;
      if(channel_id !== null){
        if(channel_id !== undefined){
          account_id = +channel_id
        }
      }else{
        account_id = this.single_communication_channel.email_account.id
      }
      if (email) {
        let contact_data = {
          search_term: this.search_contact,
          last_contact_id: 0,
          account_id,
          limit: 50
        }

        this.allGroupContact(contact_data)
      } else {
        let contact_data = {
          search_term: this.search_contact,
          last_contact_id: 0,
          account_id,
          limit: 50
        }

        this.allGroupContact(contact_data)
      }
    },

    showEmailMessages(data) {
      this.is_contact_details = true;
      this.is_group_view = false;
      this.active_email_id = data.id;
      this.active_email = data.contact
      this.emailDetails = data;

      const account_id = localStorage.getItem('email_account_id')
      let channel_id;
      if(account_id !== null){
        if(account_id !== undefined){
          channel_id = +account_id
        }
      }else{
        channel_id = this.single_communication_channel.id
      }

      // add folder to 
      if (data.folders !== null) {
        if (!this.folders.some(item => item.id === data.folders.id)) {
          data.folders.forEach((item) => {
            const main_folder = item;
            main_folder.sub = []
            this.getMainFolder(main_folder)
          })
        }
      }

      const emails = {
        email: data.contact,
        search_term: "",
        page: 1,
        per_page: this.perPage,
        channel_id,
      }
      this.allInbox(emails)
        .then(() => {
          this.is_details = false;
          this.$modal.hide('email-message-modal')
          // this.currentPage = 1
          
          // for (let index = 0; index < data.folders.length; index++) {
          //   const folder = data.folders[index];
          //   this.loadFolders('contact', folder, true, folder.id)
          // }
          
        })
        .catch((error) => {
          return error;
        });
    },
    showGroupMessages(data) {
      this.is_contact_details = false;
      this.is_group_view = true;
      this.active_email_id = data.id;
      this.active_group_email_id = data.id;
      this.active_email = data.contact
      this.emailDetails = data;
      const channel_id = localStorage.getItem('email_account_id')
      let account_id;
      if(channel_id !== null){
        if(channel_id !== undefined){
          account_id = +channel_id
        }
      }else{
        account_id = this.single_communication_channel.email_account.id
      }

      const emails = {
        group_id: data.id,
        search_term: "",
        page: 1,
        per_page: this.perPage,
        sort_column: "",
        sort_direction: "",
        channel_id: account_id,
      }
      this.allGroupInbox(emails)
        .then(() => {
          this.is_details = false;
          // this.currentPage = 1
        })
        .catch((error) => {
          return error;
        });
    },
    showWriteMessages(data) {
      this.is_contact_details = true;
      this.active_email_id = data.id;
      this.active_email = data.contact
      this.emailDetails = data;
      const channel_id = localStorage.getItem('email_account_id')
      let account_id;
      if(channel_id !== null){
        if(channel_id !== undefined){
          account_id = +channel_id
        }
      }else{
        account_id = this.single_communication_channel.email_account.id
      }

      const emails = {
        // email: data.contact,
        search_term: "",
        page: 1,
        per_page: this.perPage,
        channel_id: account_id,
        is_draft: true
      }
      this.allWriteLaterEmails(emails)
        .then(() => {
          this.is_details = false;
          // this.currentPage = 1
        })
        .catch((error) => {
          return error;
        });
    },

    showDetails(data) {
      this.is_details = true;
      this.email_details = data;
    },
    hideDetails() {
      this.is_details = false;
      this.email_details = {};
      this.$modal.hide('email-message-modal');
      this.$modal.hide("email-reply-message-modal");
      this.$modal.hide("email-read-message-modal");
      this.is_reply_later_details = false;
      this.email_reply_later_details = {};
      this.is_read_later_details = false;
      this.email_read_later_details = {};
    },
    searchEmailMessage() {
      let data;
      const channel_id = localStorage.getItem('email_account_id')
      let account_id;
      if(channel_id !== null){
        if(channel_id !== undefined){
          account_id = +channel_id
        }
      }else{
        account_id = this.single_communication_channel.email_account.id
      }
      if (this.search_message.length > 0) {
        data = {
          email: this.active_email,
          search_term: this.search_message,
          page: 1,
          per_page: this.perPage,
          channel_id: account_id,
        };
      } else {
        data = {
          email: this.active_email,
          search_term: "",
          page: 1,
          per_page: this.perPage,
          channel_id: account_id,
        };
      }



      // if (this.date_search.length > 0) {
      //   data = { date: this.date_search };
      // }

      this.allInbox(data)
        .then(() => {
          this.is_details = false;
          // this.currentPage =this.pages
        })
        .catch((error) => {
          return error;
        });
    },

    refreshMessage() {
      const channel_id = localStorage.getItem('email_account_id')
      let account_id;
      if(channel_id !== null){
        if(channel_id !== undefined){
          account_id = +channel_id
        }
      }else{
        account_id = this.single_communication_channel.email_account.id
      }
      const data = {
        email: this.active_email,
        search_term: "",
        page: 1,
        per_page: this.perPage,
        channel_id: account_id,
      };

      this.allInbox(data)
        .then(() => {
          this.is_details = false;
          // this.currentPage =this.pages
        })
        .catch((error) => {
          return error;
        });

    },

    showSearchMessageBox() {
      this.is_search_message_box = true;
    },
    hideSearchMessageBox() {
      this.is_search_message_box = false;
    },
    onPageChange(pages) {
      const channel_id = localStorage.getItem('email_account_id')
      let account_id;
      if(channel_id !== null){
        if(channel_id !== undefined){
          account_id = +channel_id
        }
      }else{
        account_id = this.single_communication_channel.email_account.id
      }
      this.allInbox({ channel_id: account_id, page: pages, email: this.active_email, search_term: "", per_page: this.perPage })
        .then(() => {
          this.is_details = false;
        })
        .catch((error) => {
          return error;
        });
    },
    sortEmailMessage() {
      if (this.sort_email_type === "" || this.sort_email_type === "desc") {
        this.sort_email_type = "asc"
      } else if (this.sort_email_type === "asc") {
        this.sort_email_type = "desc"
      }
      const channel_id = localStorage.getItem('email_account_id')
      let account_id;
      if(channel_id !== null){
        if(channel_id !== undefined){
          account_id = +channel_id
        }
      }else{
        account_id = this.single_communication_channel.email_account.id
      }

      const data = {
        channel_id: account_id,
        page: 1,
        email: this.active_email,
        search_term: "",
        per_page: this.perPage,
        sort_direction: this.sort_email_type,
        sort_column: "",

      }
      this.allInbox(data)
        .then(() => {
          this.is_details = false;
        })
        .catch((error) => {
          return error;
        });
    },
    sortEmailMessageAlpha() {
      if (this.sort_email_type === "" || this.sort_email_type === "desc") {
        this.sort_email_type = "asc"
      } else if (this.sort_email_type === "asc") {
        this.sort_email_type = "desc"
      }
      const data = {
        channel_id: this.single_communication_channel.email_account.id,
        page: 1,
        email: this.active_email,
        search_term: "",
        per_page: this.perPage,
        sort_direction: this.sort_email_type,
        sort_column: "subject",

      }
      this.allInbox(data)
        .then(() => {
          this.is_details = false;
        })
        .catch((error) => {
          return error;
        });
    },
    loadReadLaterEmails() {
      const channel_id = localStorage.getItem('email_account_id')
      let account_id;
      if(channel_id !== null){
        if(channel_id !== undefined){
          account_id = +channel_id
        }
      }else{
        account_id = this.single_communication_channel.email_account.id
      }
      this.allReadLaterEmails(account_id)
        .then(() => {
          this.is_details = false;
        })
        .catch((error) => {
          return error;
        });
    },
    loadFolders(type, folder, is_main, main_id) {
      const ids = this.folder_dropdown_ids.filter(x => x === folder.id);
      if (ids.length !== 0) {
        this.folder_dropdown_ids = this.folder_dropdown_ids.filter(x => x !== folder.id)
      } else {
        this.folder_dropdown_ids.push(folder.id);
        const data = {
        model_type: type,
        parent_id: folder.id,
        is_main: is_main,
        name: folder.name,
        main_folder_id: main_id,
      }
      this.allFolders(data);
      }

    },
    loadReplyLaterEmails() {
      const channel_id = localStorage.getItem('email_account_id')
      let account_id;
      if(channel_id !== null){
        if(channel_id !== undefined){
          account_id = +channel_id
        }
      }else{
        account_id = this.single_communication_channel.email_account.id
      }
      this.allReplyLaterEmails(account_id)
        .then(() => {
          this.is_details = false;
        })
        .catch((error) => {
          return error;
        });
    },
    loadWriteLaterEmails() {
      const channel_id = localStorage.getItem('email_account_id')
      let account_id;
      if(channel_id !== null){
        if(channel_id !== undefined){
          account_id = +channel_id
        }
      }else{
        account_id = this.single_communication_channel.email_account.id
      }
      this.allWriteLaterEmails({ channel_id: account_id, page: 1, email: this.active_email, search_term: "", per_page: this.perPage })
        .then(() => {
          this.is_details = false;
        })
        .catch((error) => {
          return error;
        });
    },
    date: function (date) {
      return moment(date).format("ddd DD-MMM-YY hh:mm a");
    },

    sendEmailWithSelectContact(email) {
      let emails = [];
      if (this.is_group_view) {
        email.forEach((item) => {
          const data = item.unified_contact.contact;
          emails.push(data);
        })
      } else {
        emails = [email];
      }
      const channel_id = localStorage.getItem('email_account_id')
      let account_id;
      if(channel_id !== null){
        if(channel_id !== undefined){
          account_id = +channel_id
        }
      }else{
        account_id = this.single_communication_channel.email_account.id
      }
      const data = {
        id: uid(),
        is_cc_enable: false,
        is_bcc_enable: false,
        from: this.single_communication_channel.email_account.username,
        to: emails,
        cc: [],
        bcc: [],
        subject: "",
        body: "",
        html_body: "",
        channel_id: account_id,
        attachments: [],
        is_draft: false,
      };
      this.composeView.push(data);
      this.$modal.show('compose-modal');
    },
    showCompose() {
      if (this.composeView.length < 1) {
        const channel_id = localStorage.getItem('email_account_id')
      let account_id;
      if(channel_id !== null){
        if(channel_id !== undefined){
          account_id = +channel_id
        }
      }else{
        account_id = this.single_communication_channel.email_account.id
      }
        const data = {
          id: uid(),
          is_cc_enable: false,
          is_bcc_enable: false,
          from: this.single_communication_channel.email_account.username,
          to: [],
          cc: [],
          bcc: [],
          subject: "",
          body: "",
          html_body: "",
          email_account_id: account_id,
          attachments: [],
          is_draft: false,
        };
        this.composeView.push(data);
      }
    },
    showWriteLaterCompose(item) {
      if (this.composeView.length < 1) {
        let toEmails = [];
        item.to.forEach((elm) => {
          const email = elm.Email;
          toEmails.push(email)
        })

        let ccEmails = [];
        item.cc.forEach((elm) => {
          const email = elm.Email;
          ccEmails.push(email)
        })

        let bccEmails = [];
        item.bcc.forEach((elm) => {
          const email = elm.Email;
          bccEmails.push(email)
        })
        const data = {
          id: uid(),
          is_cc_enable: false,
          is_bcc_enable: false,
          from: this.single_communication_channel.email_account.username,
          to: toEmails,
          cc: ccEmails,
          bcc: bccEmails,
          subject: item.subject,
          body: item.body,
          html_body: item.html_body,
          channel_id: this.single_communication_channel.id,
          attachments: [],
          is_draft: false,
        };
        this.composeView.push(data);
        this.$modal.hide('write-later-modal');
      }
    },
    removeMailBox(item) {
      let data = this.composeView.filter((x) => x !== item);
      this.composeView = data;
      this.$modal.hide('compose-modal');
    },
    enableCc(id) {
      const updatedData = this.composeView.map((obj) =>
        obj.id === id ? { ...obj, is_cc_enable: true } : obj
      );
      this.composeView = updatedData;
    },
    enableBcc(id) {
      const updatedData = this.composeView.map((obj) =>
        obj.id === id ? { ...obj, is_bcc_enable: true } : obj
      );
      this.composeView = updatedData;
    },
    enableStar(star, id) {
      this.updateInbox({ id: id, e_star: !star })
        .then(() => {
          this.showEmail(this.active_email_id);
        })
        .catch((error) => {
          this.$bvToast.toast(`${error.error}`, {
            title: `Error !`,
            toaster: "b-toaster-top-right",
            solid: true,
            appendToast: false,
            variant: "danger",
          });
        });
    },
    checkScreen() {
      const windowWidth = window.innerWidth;
      if (windowWidth <= 1600) {
        this.body_string = 40;
        this.title_string = 25;
        return;
      }
    },
    showMessageSearchbox() {
      this.message_search_box = !this.message_search_box;
    },
    deleteData(id) {
      Swal.fire({
        title: "Are you sure?",
        text: "You want to delete this email message !",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.value) {
          this.deleteInbox(id)
            .then(() => {
              Swal.fire(
                "Deleted!",
                "email message is deleted successfully",
                "success"
              );
              this.showEmail(this.active_email_id);
              this.hideDetails();
            })
            .catch((error) => {
              this.$bvToast.toast(`${error.error}`, {
                title: `Error !`,
                toaster: "b-toaster-top-right",
                solid: true,
                appendToast: false,
                variant: "danger",
              });
            });
        }
      });
    },

    deleteContactData(id) {
      Swal.fire({
        title: "Are you sure?",
        text: "Do you want to delete this contact?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete",
        confirmButtonColor: '#f52626',
        cancelButtonColor: '#e5e9f2',
      }).then((result) => {
        if (result.value) {
          this.deleteContact(id)
            .then(() => {
              Swal.fire(
                "Deleted!",
                "email contact is deleted successfully",
                "success"
              );
              this.is_contact_details = false;
              this.reloadContacts();
            })
            .catch((error) => {
              this.$bvToast.toast(`${error.error}`, {
                title: `Error !`,
                toaster: "b-toaster-top-right",
                solid: true,
                appendToast: false,
                variant: "danger",
              });
            });
        }
      });
    },

    checkEmails(id) {
      const check_id = this.check_emails?.filter((x) => x === id);
      if (check_id.length > 0) {
        const new_id = this.check_emails?.filter((x) => x != id);
        this.check_emails = new_id;
      }
      if (check_id.length === 0) {
        this.check_emails.push(id);
      }
    },
    bulkDelete() {
      Swal.fire({
        title: "Are you sure?",
        text: "You want to delete this email messages !",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#ff3d60",
        confirmButtonText: "Yes, delete it!",
        cancelButtonColor: "#ccc",

      }).then((result) => {
        if (result.value) {
          this.deleteBulkEmails({ email_ids: this.check_emails })
            .then(() => {
              Swal.fire(
                "Deleted!",
                "email messages been deleted successfully",
                "success"
              );
              this.showEmail(this.active_email_id);
              this.hideDetails();
              this.check_emails = [];
            })
            .catch((error) => {
              this.$bvToast.toast(`${error.error}`, {
                title: `Error !`,
                toaster: "b-toaster-top-right",
                solid: true,
                appendToast: false,
                variant: "danger",
              });
            });
        }
      });
    },
    deleteReplyLater(id) {
      Swal.fire({
        title: "Are you sure?",
        text: "You want to delete this email message !",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.value) {
          this.deleteInbox(id)
            .then(() => {
              Swal.fire(
                "Deleted!",
                "email message is deleted successfully",
                "success"
              );
              this.showEmail(this.active_email_id);
              this.hideDetails();
            })
            .catch((error) => {
              this.$bvToast.toast(`${error.error}`, {
                title: `Error !`,
                toaster: "b-toaster-top-right",
                solid: true,
                appendToast: false,
                variant: "danger",
              });
            });
        }
      });
    },

    deleteReadLater(id) {
      Swal.fire({
        title: "Are you sure?",
        text: "You want to delete this email message !",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.value) {
          this.deleteInbox(id)
            .then(() => {
              Swal.fire(
                "Deleted!",
                "email message is deleted successfully",
                "success"
              );
              this.showEmail(this.active_email_id);
              this.hideDetails();
            })
            .catch((error) => {
              this.$bvToast.toast(`${error.error}`, {
                title: `Error !`,
                toaster: "b-toaster-top-right",
                solid: true,
                appendToast: false,
                variant: "danger",
              });
            });
        }
      });
    },
    deleteWriteLater(id) {
      Swal.fire({
        title: "Are you sure?",
        text: "You want to delete this email message !",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.value) {
          this.deleteInbox(id)
            .then(() => {
              Swal.fire(
                "Deleted!",
                "email message is deleted successfully",
                "success"
              );
              this.showEmail(this.active_email_id);
              this.hideDetails();
            })
            .catch((error) => {
              this.$bvToast.toast(`${error.error}`, {
                title: `Error !`,
                toaster: "b-toaster-top-right",
                solid: true,
                appendToast: false,
                variant: "danger",
              });
            });
        }
      });
    },
    showContactSearch() {
      this.is_contact_search = !this.is_contact_search;
    },
    showMessageSearch() {
      this.is_message_search = !this.is_message_search;
    },
    loadMore() {
      const emails = this.email_contact_data;
      // const last_contact_data = emails.pop();
      const last_data = emails.slice(-1)[0];

      const channel_id = localStorage.getItem('email_account_id')
      let account_id;
      if(channel_id !== null){
        if(channel_id !== undefined){
          account_id = +channel_id
        }
      }else{
        account_id = this.single_communication_channel.email_account.id
      }

      let contact_data = {
        search_term: "",
        last_contact_id: last_data.id,
        account_id,
        limit: 30
      }

      this.loadMoreEmailContact(contact_data)
    },
    reloadContacts() {
      const channel_id = localStorage.getItem('email_account_id')
      let account_id;
      if(channel_id !== null){
        if(channel_id !== undefined){
          account_id = +channel_id
        }
      }else{
        account_id = this.single_communication_channel.email_account.id
      }

      let contact_data = {
        search_term: "",
        last_contact_id: 0,
        account_id,
        limit: 50
      }
      this.allEmailContact(contact_data)
    },
    editContactData(data) {
      this.display_name = data.display_name
      this.contact = data.contact
      this.first_name = data.first_name
      this.last_name = data.last_name
      this.middle_name = data.middle_name
      this.facebook = data.facebook
      this.instagram = data.instagram
      this.linked_in = data.linked_in
      this.whats_app = data.whats_app
      this.skype = data.skype
      this.telegram = data.telegram
      this.cxm = data.cxm
      this.cxv = data.cxv
      this.phone_number = data.phone_number
      this.phone_number_two = data.phone_number_two
      this.contact_type = data.contact_type
      this.email = data.email
      this.email_two = data.email_two
      this.home_address = data.home_address
      this.home_address_two = data.home_address_two
      this.city = data.city
      this.state = data.state
      this.country = data.country
      this.postal_code = data.postal_code
      this.business_address = data.business_address
      this.business_address_two = data.business_address_two
      this.business_city = data.business_city
      this.business_state = data.business_state
      this.business_country = data.business_country
      this.business_postal_code = data.business_postal_code
      this.mailing_address = data.mailing_address
      this.mailing_address_two = data.mailing_address_two
      this.mailing_city = data.mailing_city
      this.mailing_state = data.mailing_state
      this.mailing_country = data.mailing_country
      this.mailing_postal_code = data.mailing_postal_code
      this.$modal.show("edit-contact-modal")
    },
    editGroupContactData(data) {
      this.group_contact = data.name
      this.$modal.show("edit-group-contact-modal")
    },
    saveNewContact() {
      const channel_id = localStorage.getItem('email_account_id')
      let account_id;
      if(channel_id !== null){
        if(channel_id !== undefined){
          account_id = +channel_id
        }
      }else{
        account_id = this.single_communication_channel.email_account.id
      }

      const contact_data = {
        display_name: this.display_name,
        contact: this.contact,
        communication_channel_id: account_id,
        contact_photo: this.photo,
        first_name: this.first_name,
        last_name: this.last_name,
        middle_name: this.middle_name,
        facebook: this.facebook,
        instagram: this.instagram,
        linked_in: this.linked_in,
        whats_app: this.whats_app,
        skype: this.skype,
        telegram: this.telegram,
        cxm: this.cxm,
        cxv: this.cxv,
        phone_number: this.phone_number,
        phone_number_two: this.phone_number_two,
        contact_type: this.contact_type,
        email: this.email,
        email_two: this.email_two,
        home_address: this.home_address,
        home_address_two: this.home_address_two,
        city: this.city,
        state: this.state,
        country: this.country,
        postal_code: this.postal_code,
        business_address: this.business_address,
        business_address_two: this.business_address_two,
        business_city: this.business_city,
        business_state: this.business_state,
        business_country: this.business_country,
        business_postal_code: this.business_postal_code,
        mailing_address: this.mailing_address,
        mailing_address_two: this.mailing_address_two,
        mailing_city: this.mailing_city,
        mailing_state: this.mailing_state,
        mailing_country: this.mailing_country,
        mailing_postal_code: this.mailing_postal_code,
      }.

      this.addContact(contact_data).then(() => {
        this.$modal.hide("add-contact-modal")
        const channel_id = localStorage.getItem('email_account_id')
      let account_id;
      if(channel_id !== null){
        if(channel_id !== undefined){
          account_id = +channel_id
        }
      }else{
        account_id = this.single_communication_channel.email_account.id
      }

        let contact_data = {
          search_term: "",
          last_contact_id: 0,
          account_id,
          limit: 100
        }
        this.allEmailContact(contact_data).then(() => {
          this.emptyContact();
        });
      })
    },

    saveUpdatedContact() {
      const channel_id = localStorage.getItem('email_account_id')
      let account_id;
      if(channel_id !== null){
        if(channel_id !== undefined){
          account_id = +channel_id
        }
      }else{
        account_id = this.single_communication_channel.email_account.id
      }

      const contact_data = {
        communication_channel_id:this.single_communication_channel?.id,
        id: this.active_email_id,
        display_name: this.display_name,
        contact: this.contact,
        channel_id: account_id,
        contact_photo: this.photo,
        first_name: this.first_name,
        last_name: this.last_name,
        middle_name: this.middle_name,
        facebook: this.facebook,
        instagram: this.instagram,
        linked_in: this.linked_in,
        whats_app: this.whats_app,
        skype: this.skype,
        telegram: this.telegram,
        cxm: this.cxm,
        cxv: this.cxv,
        phone_number: this.phone_number,
        phone_number_two: this.phone_number_two,
        contact_type: this.contact_type,
        email: this.email,
        email_two: this.email_two,
        home_address: this.home_address,
        home_address_two: this.home_address_two,
        city: this.city,
        state: this.state,
        country: this.country,
        postal_code: this.postal_code,
        business_address: this.business_address,
        business_address_two: this.business_address_two,
        business_city: this.business_city,
        business_state: this.business_state,
        business_country: this.business_country,
        business_postal_code: this.business_postal_code,
        mailing_address: this.mailing_address,
        mailing_address_two: this.mailing_address_two,
        mailing_city: this.mailing_city,
        mailing_state: this.mailing_state,
        mailing_country: this.mailing_country,
        mailing_postal_code: this.mailing_postal_code,
      }
      console.log(account_id);
      alert(JSON.stringify(contact_data))
      this.updateContact(contact_data).then(() => {
        this.$modal.hide("edit-contact-modal")
        this.emptyContact();
      })
    },
    emptyContact() {

      this.display_name = ""
      this.contact = ""
      this.channel_id = ""
      this.contact_photo = ""
      this.first_name = ""
      this.last_name = ""
      this.middle_name = ""
      this.facebook = ""
      this.instagram = ""
      this.linked_in = ""
      this.whats_app = ""
      this.skype = ""
      this.telegram = ""
      this.cxm = ""
      this.cxv = ""
      this.phone_number = ""
      this.phone_number_two = ""
      this.contact_type = ""
      this.email = ""
      this.email_two = ""
      this.home_address = ""
      this.home_address_two = ""
      this.city = ""
      this.state = ""
      this.country = ""
      this.postal_code = ""
      this.business_address = ""
      this.business_address_two = ""
      this.business_city = ""
      this.business_state = ""
      this.business_country = ""
      this.business_postal_code = ""
      this.mailing_address = ""
      this.mailing_address_two = ""
      this.mailing_city = ""
      this.mailing_state = ""
      this.mailing_country = ""
      this.mailing_postal_code = ""

    },
    addTagsToContactsModal() {
      this.$modal.show("tags-to-contact-modal");
    },
    addNewTags() {
      let tag_data = [];
      const channel_id = localStorage.getItem('email_account_id')
      let account_id;
      if(channel_id !== null){
        if(channel_id !== undefined){
          account_id = +channel_id
        }
      }else{
        account_id = this.single_communication_channel.email_account.id
      }

      this.tag_names.forEach((item) => {
        const elm = { communication_channel_id: account_id, value: item }
        tag_data.push(elm);
      })
      const data = tag_data;
      this.addTags(data).then(() => {
        this.$modal.hide("tags-modal")
        this.allTags(account_id);
        this.tag_names = [];
      })
    },
    loadContacts(data) {
      const channel_id = localStorage.getItem('email_account_id')
      let account_id;
      if(channel_id !== null){
        if(channel_id !== undefined){
          account_id = +channel_id
        }
      }else{
        account_id = this.single_communication_channel.email_account.id
      }
      let contact_data = {
        search_term: "",
        last_contact_id: 0,
        account_id,
        limit: 100
      }


      this.allEmailContact(contact_data);
      let vm = this;
      setTimeout(function () {
        const details = vm.email_contact_data.filter(item => item.id === data.id)[0];
        vm.emailDetails = details;
      }, 2000);
    },
    addTagsToContacts(data) {
      let tag_data = [];
      this.contact_tags.forEach((item) => {
        const elm = item.id
        tag_data.push(elm);
      })
      const tags_data = { contact_id: this.active_email_id, tag_ids: tag_data };
      this.addContactTags(tags_data).then(() => {
        this.$modal.hide("tags-to-contact-modal")
        this.loadContacts(data);
        this.tag_data = [];
      })
    },
    firstGroupData(data) {
      if (data?.group_members === null) return;
      const item = data.group_members[0]
      const datacontact = item?.unified_contact;
      const name = datacontact?.display_name;
      const initials = this.getInitials(name)
      return initials
    },

    secondGroupData(data) {
      if (data?.group_members === null) return;
      const item = data?.group_members[1]
      const datacontact = item?.unified_contact;
      const name = datacontact?.display_name;
      const initials = this.getInitials(name)
      return initials
    },
    createFolder() {
      const data = {
        // channel_id: this.single_communication_channel.id,
        name: this.folder_name,
        model_id: this.emailDetails.id,
        model_type: "contact",
        parent_id: this.parent_id,
      }

      this.addFolder(data).then(() => {
        this.$modal.hide("folder-modal")
        // this.loadFolders();
        this.loadContacts(this.emailDetails);
        this.folder_name = "";
        this.parent_id = null;
      })
    },
    updateFolder() {
      const data = {
        id: this.active_folder_id,
        name: this.folder_name,
        model_id: this.emailDetails.id,
        model_type: "contact",
        parent_id: this.parent_id,
      }

      this.editFolder(data).then(() => {
        this.$modal.hide("edit-folder-modal")
        this.loadContacts(this.emailDetails);
        this.folder_name = "";
        this.parent_id = null;
      })
    },
    deleteTags(id) {
      Swal.fire({
        title: "Are you sure?",
        text: "You want to delete this tag !",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#ff3d60",
        confirmButtonText: "Yes, delete it!",
        cancelButtonColor: "#ccc",

      }).then((result) => {
        if (result.value) {
          this.removeTags({ tag_id: id, contact_id: this.emailDetails.id })
            .then(() => {
              this.loadContacts(this.emailDetails);
            })
            .catch((error) => {
              this.$bvToast.toast(`${error.error}`, {
                title: `Error !`,
                toaster: "b-toaster-top-right",
                solid: true,
                appendToast: false,
                variant: "danger",
              });
            });
        }
      });
    },
    showEditFolder(data) {
      this.folder_name = data.name;
      this.active_folder_id = data.id,
        this.$modal.show('edit-folder-modal');
    },
    deleteFolderData(id) {
      Swal.fire({
        title: "Are you sure?",
        text: "You want to delete this folder !",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#ff3d60",
        confirmButtonText: "Yes, delete it!",
        cancelButtonColor: "#ccc",

      }).then((result) => {
        const channel_id = localStorage.getItem('email_account_id')
      let account_id;
      if(channel_id !== null){
        if(channel_id !== undefined){
          account_id = +channel_id
        }
      }else{
        account_id = this.single_communication_channel.email_account.id
      }
        if (result.value) {
          this.deleteFolder(id)
            .then(() => {
              let contact_data = {
                search_term: "",
                last_contact_id: 0,
                account_id,
                limit: 24
              }
              this.allEmailContact(contact_data)
            })
            .catch((error) => {
              this.$bvToast.toast(`${error.error}`, {
                title: `Error !`,
                toaster: "b-toaster-top-right",
                solid: true,
                appendToast: false,
                variant: "danger",
              });
            });
        }
      });
    },
    onDragStart(evt, item) {
      evt.dataTransfer.dropEffect = 'move'
      evt.dataTransfer.effectAllowed = 'move'
      evt.dataTransfer.setData('itemID', item.id)
      this.active_draggable_message_id = item.id;
    },
    onDrop(evt, item) {
      const channel_id = localStorage.getItem('email_account_id')
      let account_id;
      if(channel_id !== null){
        if(channel_id !== undefined){
          account_id = +channel_id
        }
      }else{
        account_id = this.single_communication_channel.email_account.id
      }

      evt.dataTransfer.getData('itemID');
      const data = {
        email: this.active_email,
        search_term: "",
        page: 1,
        per_page: this.perPage,
        channel_id: account_id
      };
      this.moveEmailMessageToFolder({ email_id: this.active_draggable_message_id, folder_id: item.id }).then(() => {
        this.allInbox(data)
          .then(() => {
            this.is_details = false;
            // this.currentPage =this.pages
          })
          .catch((error) => {
            return error;
          });
      });
    },

    loadFolderEmails(data) {
      this.folderEmails(data.id);
    }

  },

  mounted() {
    this.account = this.email_accounts.data
    this.active_email_id = localStorage.getItem("active_email_id");
  }

}
</script>

<style>

</style>