<template>
    <div>
       
        <div class="folder_view_card" @click.stop="loadFolder(item)" :style="indent" v-for="item in folderData" :key="item.id">
            
            <div class="icon-view" :class="dropdown_menu_icons.filter(x => x === item.id)[0] === item.id ? 'is_icon_dropdown' : ''"><i class="mdi mdi-chevron-right"></i></div>

            <div class="folder__image__view">
                <div class="main_folder_view" @contextmenu.prevent="onRightFolderClick(item.id)">
                    <img :src="folderImg" alt="folder" />
                    <span> {{ item.name }}</span>
                </div>
                <div class="dropdown__menu" v-if="folder_menu_dropdown_id === item.id"
                    v-click-outside="onClickOutsideFolderMenu">
                    <div class="menu_item">
                        <span>Add</span>
                    </div>
                    <div class="menu_item">
                        <span>Rename</span>
                    </div>
                    <div class="menu_item">
                        <span>Delete</span>
                    </div>
                </div>
               <div class="" style="display:none">{{ subFolder }}</div>
                <SubFolder v-if="item.sub.length > 0 || dropdown_menu_icons && dropdown_menu_icons.filter(x => x === item.id)[0] === item.id" :dataTree="subFolder" :folderImg="folderImg"
                     :indent="indent" :depth="depth + 1"
                     :loadFolder="loadFolder"
                     :dropdown_menu_icons="dropdown_menu_icons"
                    :folder_menu_dropdown_id="folder_menu_dropdown_id" :onRightFolderClick="onRightFolderClick"
                    :onClickOutsideFolderMenu="onClickOutsideFolderMenu" />
            </div>
        </div>
    </div>
</template>

<script>
import folderImg from "../../assets/images/folder.svg";
import { emailAccountMethods } from "../../state/helpers/eaccount";
import SubFolder from "../subfolder.vue";
export default {
    components: {
        SubFolder,
    },
    props: ['folder'],
    data() {
        return {
            folderImg,
            depth: 0,
            folder_menu_dropdown_id: null,
            folderData: this.folder,
            activeSub: [],
            subFolder: [],
            dropdown_menu_icons: [],
      
        };
    },
    computed: {
        indent() {
            return { transform: `translate(${this.depth * 10}px) !important` };
        },
         
    },
    watch: {
    activeSub: {
      handler(data) {
        this.subFolder = data
      },
      // force eager callback execution
      deep: true
    }
  },
    methods: {
        ...emailAccountMethods,
        onRightFolderClick(id) {
            this.folder_menu_dropdown_id = id;
        },
        onClickOutsideFolderMenu() {
            this.folder_menu_dropdown_id = "";
        },
        loadFolder(folder){
            const checkId = this.dropdown_menu_icons.filter(item => item === folder.id)
            if(checkId[0] === folder.id) {
               const ids = this.dropdown_menu_icons.filter(item => item !== folder.id)
               this.dropdown_menu_icons = ids;
            }else{
                
                const data = {model_type: folder.model_type, parent_id: folder.id}
            this.allFolders({data, clicked_id: folder.id}).then(() => {
                this.dropdown_menu_icons.push(folder.id)
                if(folder.parent_id === null) {
                    return this.activeSub = folder.sub
                }
            })
            }

           
        }
    },
};
</script>

<style lang="scss" scoped>

</style>
